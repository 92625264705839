import React, { useRef } from "react";
import {
  Collapse,
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  Flex,
  useDisclosure,
  IconButton,
  ListItem,
  List,
  Text,
} from "@chakra-ui/react";
import CollapseIcon from "../../../assets/images/collapse.svg";
import PrimaryHeading from "../../../components/PrimaryHeading";
import PrimaryFormLabel from "../../../components/PrimaryFormLabel";
import { handleViewFile, removeFile } from "../form/utils";
import { ViewIcon } from "@chakra-ui/icons";
import { IoClose } from "react-icons/io5";

const PatientPassportSection = ({
  formErrors,
  formData,
  setFormData,
  setFormErrors,
  handlePassportFileChange,
  isRequired,
  isAutopopulateData = false,
  autoPopulatedFields = [],
}) => {
  const { isOpen: isSectionOpen, onToggle: toggleSection } = useDisclosure({
    defaultIsOpen: true,
  });

  const fileInputRefs = useRef({});
  return (
    <>
      <Flex alignItems="center" className="collapse-section-header">
        <PrimaryHeading>Patient Passport</PrimaryHeading>
        <img
          src={CollapseIcon}
          width={"24px"}
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            transition: "transform 0.3s ease",
            transform: isSectionOpen ? "rotate(0)" : "rotate(180deg)",
          }}
          onClick={toggleSection}
        />
      </Flex>
      <Collapse in={isSectionOpen} animateOpacity>
        <Box className="single-box" bg="white" borderRadius="10px">
          <FormControl isInvalid={formErrors.passport} isRequired={isRequired}>
            <PrimaryFormLabel htmlFor="passport">
              Upload Passport
            </PrimaryFormLabel>
            <Input
              ref={(el) => (fileInputRefs.current.passport = el)}
              variant="filled"
              h="auto"
              p="10px"
              id="passport"
              name="passport"
              type="file"
              accept=".pdf,.jpg,.jpeg,.png"
              onChange={handlePassportFileChange}
              disabled={isAutopopulateData && autoPopulatedFields["passport"]}
            />
            <FormHelperText>
              Note: Please upload file in PNG, JPG, JPEG, or PDF format.
            </FormHelperText>
            <FormErrorMessage>{formErrors.passport}</FormErrorMessage>
            {formData.passport && formData.passport !== "" && (
              <List mt={3}>
                <ListItem display="flex" alignItems="center">
                  {/* <Box flex="1">
                    {formData.passport.name} (
                    {(formData.passport.size / (1024 * 1024)).toFixed(2)} MB)
                  </Box> */}
                  <Box flex="1">
                    <Text fontSize="sm" isTruncated>
                      {typeof formData.passport === "string"
                        ? new URL(formData.passport).pathname.split("/").pop()
                        : formData.passport.name || "No file selected"}
                    </Text>

                    {typeof formData.passport !== "string" &&
                      formData.passport?.size && (
                        <Text fontSize="xs" color="gray.500">
                          {(formData.passport.size / (1024 * 1024)).toFixed(2)}{" "}
                          MB
                        </Text>
                      )}
                  </Box>
                  <IconButton
                    variant="ghost"
                    colorScheme="blue"
                    size="sm"
                    aria-label="View"
                    icon={<ViewIcon />}
                    onClick={() => handleViewFile(formData.passport)}
                    mr="2"
                  />
                  {!(isAutopopulateData && autoPopulatedFields["passport"]) && (
                    <IconButton
                      variant="ghost"
                      colorScheme="red"
                      size="sm"
                      aria-label="Remove"
                      icon={<IoClose />}
                      onClick={() =>
                        removeFile(
                          fileInputRefs,
                          "passport",
                          setFormData,
                          setFormErrors
                        )
                      }
                    />
                  )}
                </ListItem>
              </List>
            )}
          </FormControl>
        </Box>
      </Collapse>
    </>
  );
};

export default PatientPassportSection;
