import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";

const AttachmentViewModal = ({
  isOpen,
  onClose,
  files,
  onAddFile,
  onDeleteFile,
  onSubmit,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevFile = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? files.length - 1 : prevIndex - 1
    );
  };

  const handleNextFile = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === files.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDeleteFile = () => {
    onDeleteFile(currentIndex);
    if (files.length === 1) {
      setCurrentIndex(0);
    } else if (currentIndex === files.length - 1) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const currentFile = files[currentIndex];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="primary.1" textAlign={"center"}>
          Uploaded Files
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {files && files.length > 0 ? (
            <VStack spacing={4} width="100%">
              {/* Display file in iframe or appropriate tag */}
              <Box width="100%" height="100%" position="relative">
                {currentFile.type.startsWith("image/") ? (
                  <iframe
                    src={URL.createObjectURL(currentFile)}
                    title={currentFile.name}
                    // height="700px"
                    // width="800px"
                    style={{
                      width: "100%",
                      height: "calc(100vh - 400px)", // Adjust based on modal header/footer height
                      border: "none",
                      maxHeight: "80vh", // Ensures iframe doesn't exceed 80% of viewport height
                    }}
                  ></iframe>
                ) : (
                  <iframe
                    src={URL.createObjectURL(currentFile)}
                    title={currentFile.name}
                    // height="700px"
                    // width="400px"
                    style={{
                      width: "100%",
                      height: "calc(100vh - 200px)", // Adjust based on modal header/footer height
                      border: "none",
                      maxHeight: "80vh", // Ensures iframe doesn't exceed 80% of viewport height
                    }}
                  ></iframe>
                )}
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton
                  icon={<ArrowLeftIcon />}
                  onClick={handlePrevFile}
                  aria-label="Previous file"
                />
                <Text mr="5px" ml="5px">
                  {currentIndex + 1} of {files.length}
                </Text>
                <IconButton
                  icon={<ArrowRightIcon />}
                  onClick={handleNextFile}
                  aria-label="Next file"
                />
              </Box>
            </VStack>
          ) : (
            <Text>No files uploaded yet.</Text>
          )}
        </ModalBody>

        <ModalFooter display="flex" justifyContent="space-between">
          <Box>
            <Button colorScheme="blue" mr={3} onClick={onAddFile}>
              Add More Files
            </Button>
            {files.length > 0 && (
              <Button
                colorScheme="red"
                variant="ghost"
                onClick={handleDeleteFile}
              >
                Remove
              </Button>
            )}
          </Box>

          <Button colorScheme="green" onClick={onSubmit}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AttachmentViewModal;
