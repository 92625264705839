import React from "react";
import { FormControl, Input, FormErrorMessage } from "@chakra-ui/react";
import PrimaryFormLabel from "./PrimaryFormLabel";
import AlphaInput from "./AlphaInput";

const FormInput = ({
  id,
  label,
  type = "text",
  value,
  onChange,
  placeholder,
  isInvalid,
  errorMessage,
  isRequired,
  flex,
  mr,
  name,
  maxDate,
  disabled,
  variant = "outline",
}) => {
  const today = new Date();
  const minDate = new Date().toISOString().split("T")[0];
  const minDobDate = new Date(
    today.getFullYear() - 120,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];

  return (
    <FormControl
      className="form-input"
      isInvalid={isInvalid}
      flex={flex}
      mr={mr}
      isRequired={isRequired}
    >
      <PrimaryFormLabel htmlFor={id}>{label}</PrimaryFormLabel>
      {label === "Name" || id === "name" ? (
        <AlphaInput
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          variant={variant}
          regex={/^[a-zA-Z ]*$/}
          maxLength={40}
          isInvalid={isInvalid}
          disabled={disabled}
          isRequired={isRequired}
        />
      ) : (
        <Input
          id={id}
          name={id}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          isDisabled={disabled}
          variant={variant}
          min={
            name === "avoidPastDate" && type === "date"
              ? minDate
              : name === "dob" && type === "date"
              ? minDobDate
              : undefined
          }
          max={name === "dob" && type === "date" ? maxDate : undefined}
        />
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default FormInput;
