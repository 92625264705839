import { put, all, takeLatest } from "redux-saga/effects";
// import { browserRedirect } from "../../helpers/helpers";
import {
  LOGIN_REQUEST,
  loginSuccess,
  loginFailure,
  REGISTER_REQUEST,
  registerSuccess,
  registerFailure,
  VERIFY_OTP_REQUEST,
  verifyOTPSuccess,
  verifyOTPError,
  RESEND_OTP_REQUEST,
  resendOTPSuccess,
  resendOTPError,
  SHOW_LOADER,
  HIDE_LOADER,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST,
  fetchProfileDetailsSuccess,
  // fetchProfileDetailsFailure,
  FETCH_PROFILE_DETAILS_REQUEST,
  fetchProfileDetailsPostSuccess,
  apiError,
  FETCH_DESIGNATION_REQUEST,
  fetchDesignationSuccess,
  exceptionSessionExpire,
  exceptionAPIError,
  exceptionError,
  sendOTPSuccess,
  sendOTPFailure,
  SEND_OTP_REQUEST,
  verifyUserOTPSuccess,
  verifyUserOTPFailure,
  VERIFY_USER_OTP_REQUEST,
  verifyRegOtpSuccess,
  FETCH_UNAUTHENTICATED_HOSPITALS_REQUEST,
  fetchHospitalsSuccess,
  LOGOUT_REQUEST,
} from "./actions";
import api from "../../../config/api";

// LOGIN Worker
function* loginWorker({ payload }) {
  try {
    yield put({ type: SHOW_LOADER }); // Dispatch action to show loader
    let response = yield api.post("qms.api.login", payload);
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        localStorage.setItem("otp_token", response.token);
        yield put(loginSuccess(response));
      } else {
        yield put(loginFailure(response));
      }
    } else if (response.status === 401) {
      console.log(
        response.data.exc_type,
        response.data.exception,
        response.data.message
      );
      yield put(loginFailure(response.data));
    }

    // yield call(browserRedirect, "/");
  } catch (err) {
    yield put(loginFailure(err.response));
  } finally {
    yield put({ type: HIDE_LOADER }); // Dispatch action to hide loader
  }
}

function* logoutWorker({ payload }) {
  try {
    yield put({ type: SHOW_LOADER });
    let response = yield api.get("logout", {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    payload.navigate("/");
    if (response.ok) {
      // localStorage.clear();
      response = response.data;
    } else if (response.status === 401) {
      // console.log(
      //   response.data.exc_type,
      //   response.data.exception,
      //   response.data.message
      // );
    }
  } catch (err) {
  } finally {
    localStorage.clear();
    yield put({ type: HIDE_LOADER });
  }
}

// Register Worker
function* registerWorker({ payload }) {
  try {
    yield put({ type: SHOW_LOADER });
    let response = yield api.post("qms.api.register", payload);
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(registerSuccess(response));
      } else {
        yield put(registerFailure(response));
      }
    } else {
      yield put(registerFailure(response.data));
    }
  } catch (err) {
    yield put(registerFailure(err.response));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

// Verify OTP Worker
function* verifyOTPWorker({ payload }) {
  const { usr, otp, otp_type } = payload;
  let token = false;
  try {
    if (otp_type === "Login") {
      token = payload.token;
    }
    let response = yield api.post("qms.api.verify_otp", {
      usr,
      otp,
      otp_type,
      token,
    });
    if (response.ok) {
      if (response.data.status === 200) {
        localStorage.removeItem("otp_token");
        if (otp_type == "Reg") {
          yield put(verifyRegOtpSuccess(response.data));
        } else {
          yield put(verifyOTPSuccess(response.data));
        }
        response = response.data;
        if (otp_type === "Login") {
          localStorage.removeItem("user");
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "user",
            JSON.stringify({
              email: response.data.email,
              fullname: response.data.fullname,
              role: response.data.role,
            })
          );
        }
        if (otp_type !== "Reg") {
          payload.navigate(payload.redirectPath);
        }

        // yield call(browserHistory.push("/h/home"));
      } else {
        yield put(verifyOTPError(response.data));
      }
    } else {
      yield put(verifyOTPError(response.data));
    }
  } catch (err) {
    yield put(verifyOTPError(err.response.data));
  }
}

// Resend OTP Worker
function* resendOTPWorker({ payload }) {
  // yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.resend_otp", payload);
    if (response.ok) {
      if (response.data.status === 200) {
        localStorage.setItem("otp_token", response.data.token);
        yield put(resendOTPSuccess(response.data));
      } else {
        yield put(resendOTPError(response.data));
      }
    }
  } catch (err) {
    yield put(resendOTPError(err.response.data));
  }
  // finally {
  //   yield put({ type: HIDE_LOADER });
  // }
}

// Forgot Password OTP Worker
function* forgotPasswordWorker({ payload }) {
  try {
    yield put({ type: SHOW_LOADER });
    let response = yield api.post("qms.api.resend_otp", payload);
    if (response.ok) {
      if (response.data.status === 200) {
        yield put(forgotPasswordSuccess(response.data));
      } else {
        yield put(forgotPasswordFailure(response.data));
      }
    }
  } catch (err) {
    yield put(forgotPasswordFailure(err.response.data));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

// Reset Password OTP Worker
function* resetPasswordWorker({ payload }) {
  try {
    yield put({ type: SHOW_LOADER });
    let response = yield api.post("qms.api.reset_password", payload);
    if (response.ok) {
      if (response.data.status === 200) {
        yield put(resetPasswordSuccess(response.data));
      } else {
        yield put(resetPasswordFailure(response.data));
      }
    }
  } catch (err) {
    yield put(resetPasswordFailure(err.response.data));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchProfileDetailsWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response;
    if (payload.action === "get") {
      let data = {};
      response = yield api.get("qms.api.user_profile", data, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    } else {
      response = yield api.post("qms.api.user_profile", payload, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    }
    if (response.ok) {
      const responseData = response.data;
      if (responseData.status === 200) {
        if (payload.action === "get") {
          yield put(fetchProfileDetailsSuccess(responseData.data));
        } else {
          yield put(fetchProfileDetailsPostSuccess(responseData));
        }
      } else {
        yield put(apiError(responseData));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchDesignationDataWorker({ payload }) {
  try {
    let response = yield api.get("qms.api.designation", payload);
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchDesignationSuccess(response));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

function* fetchHospitalsDataWorker({}) {
  var data = {};
  try {
    let response = yield api.get("qms.api.reg_hospitals", data);
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchHospitalsSuccess(response));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

function* sendUserOtpWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.send_user_otp", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });

    if (response.ok) {
      const responseData = response.data;
      if (responseData.status === 200) {
        yield put(sendOTPSuccess(responseData));
      } else if (responseData.status === 400) {
        console.log("OTP Not Send", responseData);
        yield put(sendOTPFailure(responseData));
      } else {
        yield put(sendOTPFailure(responseData));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* verifyUserOtpWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.verify_user_otp", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });

    if (response.ok) {
      const responseData = response.data;
      if (responseData.status === 200) {
        yield put(verifyUserOTPSuccess(responseData));
      } else {
        yield put(verifyUserOTPFailure(responseData));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

// authSaga
export default function* authSaga() {
  yield all([
    takeLatest(LOGIN_REQUEST, loginWorker),
    takeLatest(LOGOUT_REQUEST, logoutWorker),
    takeLatest(REGISTER_REQUEST, registerWorker),
    takeLatest(VERIFY_OTP_REQUEST, verifyOTPWorker),
    takeLatest(RESEND_OTP_REQUEST, resendOTPWorker),
    takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordWorker),
    takeLatest(RESET_PASSWORD_REQUEST, resetPasswordWorker),
    takeLatest(FETCH_PROFILE_DETAILS_REQUEST, fetchProfileDetailsWorker),
    takeLatest(FETCH_DESIGNATION_REQUEST, fetchDesignationDataWorker),
    takeLatest(SEND_OTP_REQUEST, sendUserOtpWorker),
    takeLatest(VERIFY_USER_OTP_REQUEST, verifyUserOtpWorker),
    takeLatest(
      FETCH_UNAUTHENTICATED_HOSPITALS_REQUEST,
      fetchHospitalsDataWorker
    ),
  ]);
}
