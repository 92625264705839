export const handleReportsFileChange = (
  event,
  selectedFile,
  setSelectedFile,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  name
) => {
  const files = Array.from(event.target.files);

  // Maximum number of files allowed
  const maxFiles = 50;
  const videoMaxSizeMB = 10;

  // Allowed video formats
  const videoFormats = [
    ".mp4",
    ".avi",
    ".mpeg",
    ".wmv",
    ".mov",
    ".flv",
    ".m2v",
    ".ogv",
    ".xvid",
    ".hevc",
    ".vob",
    ".mkv",
    ".webm",
    ".3gp",
    ".ts",
    ".asf",
    ".dv",
    ".swf",
    ".mxf",
    ".f4v",
    ".rmvb",
    ".h263",
    ".m4v",
    ".mpg4",
    ".3g2",
    ".dat",
    ".mk3d",
    ".ogm",
  ];

  // Helper to get file extension
  const getFileExtension = (fileName) => {
    return fileName
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();
  };

  // Validate file size
  const validateFileSize = (file) => {
    const fileExtension = getFileExtension(file.name);
    if (videoFormats.includes(`.${fileExtension}`)) {
      return file.size <= videoMaxSizeMB * 1024 * 1024; // Check size in bytes
    }
    return true; // Non-video files bypass the size check
  };

  // Filter valid and invalid files
  const validFiles = files.filter(validateFileSize);
  const invalidFiles = files.filter((file) => !validateFileSize(file));

  // Check if the total selected files exceed maxFiles limit
  if (validFiles.length + selectedFile.length > maxFiles) {
    setFormErrors({
      ...formErrors,
      reports: `You can upload a maximum of ${maxFiles} files.`,
    });
    return;
  }

  // Show an error message for files that exceed the size limit
  if (invalidFiles.length > 0) {
    setFormErrors({
      ...formErrors,
      reports: `Some Videos were not uploaded because they exceed the ${videoMaxSizeMB}MB size limit.`,
    });
  } else {
    setFormErrors({ ...formErrors, reports: false });
  }

  // Append valid files to the selected files
  if (validFiles.length > 0) {
    const updatedFiles = [...selectedFile, ...validFiles];
    setSelectedFile(updatedFiles);

    // Update formData based on the input field
    if (name === "reports") {
      setFormData({ ...formData, reports: updatedFiles });
    }
    if (name === "flightTickets") {
      setFormData({ ...formData, flightTickets: updatedFiles });
    }
  }
};

// export const calculateTotalSize = (files) => {
//   const totalSize = files.reduce(
//     (acc, file) => acc + file.size / (1024 * 1024),
//     0
//   );
//   return totalSize;
// };

export const removeFile = (
  index,
  selectedFile,
  setSelectedFile,
  formData,
  setFormData,
  formErrors,
  setFormErrors
) => {
  const updatedFiles = [...selectedFile];
  updatedFiles.splice(index, 1);
  setSelectedFile(updatedFiles);
  setFormData({ ...formData, reports: updatedFiles });
  if (updatedFiles.length < 1) {
    setFormErrors({
      ...formErrors,
      reports: "Please upload at least one File",
    });
    return;
  }
};

export const handleViewFile = (file) => {
  if (file.type.startsWith("image/") || file.type === "application/pdf") {
    window.open(URL.createObjectURL(file));
  } else {
    alert("Unsupported file type for preview. You can download the file.");
  }
};
