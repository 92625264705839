import { useLocation, Navigate, Outlet } from "react-router-dom";

export const RequireAuth = ({ allowedRoles }) => {
  const authUser = JSON.parse(localStorage.getItem("user"));
  const isAuthenticated = authUser;

  const location = useLocation();

  const hasPermission = () => {
    if (authUser && authUser?.role) {
      if (allowedRoles) {
        return allowedRoles.includes(authUser.role);
      }
      return true;
    } else {
      return false;
    }
  };

  return isAuthenticated ? (
    <>
      {hasPermission() ? (
        <Outlet />
      ) : (
        <Navigate
          to="/unauthorized"
          state={location.state ? { from: location.state.from } : null}
          replace
        />
      )}
    </>
  ) : (
    <Navigate
      to="/"
      state={location.state ? { from: location.state.from } : null}
      replace
    />
  );
};
