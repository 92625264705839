import React, { useEffect, useState } from "react";
import DashboardSecondaryCard from "../components/DashboardSecondaryCard";
import Layout from "../../../layout";
import { Box, IconButton } from "@chakra-ui/react";
import { HiEye } from "react-icons/hi";
import PatientDetailsModal from "../components/PatientDetailsModal";
import ReactDataTable from "../../../components/PrimaryTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboardSummaryRequest,
  fetchTrDetailsRequest,
  setDashboardCurrentState,
} from "../hcfStore/actions";
import DataTableColumns from "../../../components/DataTableColumns";

const DashboardView = ({}) => {
  const dispatch = useDispatch();
  const {
    getDashboardCardData,
    dashboardSummaryDetails,
    trDetails,
  } = useSelector((state) => state.hcf);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(false);

  useEffect(() => {
    let payload = {
      category: localStorage.getItem("dashboard_filter"),
      status_name: localStorage.getItem("dashboard_status"),
      summary: true,
    };
    dispatch(setDashboardCurrentState(getDashboardCardData));
    dispatch(fetchDashboardSummaryRequest(payload));
  }, []);

  useEffect(() => {
    if (trDetails && trDetails.length > 0) {
      setIsModalOpen(true);
    }
  }, [trDetails]);

  const handleViewClick = (id) => {
    let payload = {
      tr_id: id,
    };
    setSelectedPatient(id);
    dispatch(fetchTrDetailsRequest(payload));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPatient(null);
  };

  const handleSectionClick = (section) => {
    console.log("SECTION CLICKED:", section);
  };

  const dashboardFilterHeader = localStorage.getItem("dashboard_filter");
  let Header;
  if (dashboardFilterHeader === "hsp_opinion") {
    Header = "Hospital Opinion";
  } else if (dashboardFilterHeader === "travel_request") {
    Header = "Travel Request";
  } else if (dashboardFilterHeader === "flight_tickets") {
    Header = "Flight Tickets";
  } else if (dashboardFilterHeader === "pre_intimation") {
    Header = "PreIntimation";
  } else if (dashboardFilterHeader === "discharge") {
    Header = "Discharge";
  }

  return (
    <Layout header={Header} iconUrl="/h/dashboard">
      <DashboardSecondaryCard
        data={getDashboardCardData}
        onSectionClick={handleSectionClick}
      />
      <Box
        minHeight="600px"
        bgColor={"#ffff"}
        mt="20px"
        ml="10px"
        shadow="md"
        borderWidth="1px"
        borderRadius="md"
      >
        <ReactDataTable
          columns={DataTableColumns(handleViewClick)}
          data={dashboardSummaryDetails}
          handleViewClick={handleViewClick}
          selectedPatientId={selectedPatient}
        />
        {selectedPatient && trDetails && (
          <PatientDetailsModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            patient={trDetails?.[0] || []}
          />
        )}
      </Box>
    </Layout>
  );
};

export default DashboardView;
