import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Box, Alert, AlertIcon, Text, IconButton } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchNotificationRequest } from "./store/actions";
import noDataImage from "../../assets/images/NotificationBg.svg";
import { HiEye } from "react-icons/hi";
import { fetchTrDetailsRequest, resetTrdetails } from "../hcf/hcfStore/actions";
import PatientDetailsModal from "../hcf/components/PatientDetailsModal";
import ReactDataTable from "../../components/PrimaryTable";

const Notifications = () => {
  const dispatch = useDispatch();

  const { notifications } = useSelector((state) => state.notification);
  const { trDetails } = useSelector((state) => state.hcf);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const [selectedPatients, setSelectedPatients] = useState([]);
  // const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const payload = {};
    dispatch(resetTrdetails());
    dispatch(fetchNotificationRequest(payload));
  }, [dispatch]);

  useEffect(() => {
    if (trDetails && trDetails.length > 0) {
      setIsModalOpen(true);
    }
  }, [trDetails]);

  const handleViewClick = (id) => {
    let payload = {
      tr_id: id,
    };
    // setSelectedPatient(id);
    dispatch(fetchTrDetailsRequest(payload));
  };

  // const handleSelectRow = (id) => {
  //   const selectedIndex = selectedPatients.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = [...selectedPatients, id];
  //   } else {
  //     newSelected = selectedPatients.filter((selectedId) => selectedId !== id);
  //   }

  //   setSelectedPatients(newSelected);
  //   setShowDeleteButton(newSelected.length > 0);
  // };

  // const handleDelete = () => {
  //   setShowSuccessMessage(true);

  //   setSelectedPatients([]);
  //   setShowDeleteButton(false);
  //   setTimeout(() => {
  //     setShowSuccessMessage(false);
  //   }, 2000);
  // };

  const NoDataComponent = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        fontSize: "20px",
      }}
    >
      <img src={noDataImage} alt="No records" style={{ maxWidth: "30%" }} />
      <Text as="b">No notifications yet</Text>
      <Text>When you get notifications, they'll show up here</Text>
    </div>
  );

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      sortable: true,
    },

    {
      name: "TR-ID",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // cursor: "pointer"
          }}
          // onClick={() => handleViewClick(row.tr_id)}
        >
          {row.read === 0 ? (
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: "#57ACFF",
                marginRight: 8,
              }}
            />
          ) : (
            ""
          )}
          {row.tr_id}
        </div>
      ),
      sortable: true,
    },

    {
      name: "Action Description",
      selector: (row) => row.stage,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) => row.date_timeago,
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <IconButton
    //       icon={<HiEye />}
    //       onClick={() => handleViewClick(row.tr_id)}
    //     />
    //   ),
    //   ignoreRowClick: true,
    //   // allowOverflow: true,
    //   // button: true,
    // },
  ];

  return (
    <Layout header={"Notifications"} iconUrl="/h/home">
      <Box
        minHeight="600px"
        bgColor={"#ffff"}
        mt="20px"
        ml="10px"
        shadow="md"
        borderWidth="1px"
        borderRadius="md"
      >
        {/* {showDeleteButton && (
          <Flex justifyContent={"end"}>
            <Button colorScheme="red" onClick={handleDelete} mb={4}>
              Delete
            </Button>
          </Flex>
        )} */}
        {showSuccessMessage && (
          <Alert status="success" mb={4}>
            <AlertIcon />
            Successfully deleted selected patients.
          </Alert>
        )}
        <ReactDataTable
          columns={columns}
          data={notifications || []}
          noDataComponent={<NoDataComponent />}
          conditionalRowStyles={[
            {
              when: (row) => row.read === 0,
              style: {
                fontWeight: "bold",
              },
            },
            {
              when: (row) => !row.read === 1,
              style: {
                backgroundColor: "#F0F0F0",
              },
            },
          ]}
        />
        {trDetails && (
          <PatientDetailsModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            patient={trDetails?.[0] || []}
          />
        )}
      </Box>
    </Layout>
  );
};

export default Notifications;
