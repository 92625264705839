import { IconButton } from "@chakra-ui/react";
import PrimaryText from "./PrimaryText";
import { HiEye } from "react-icons/hi";

const DataTableColumns = (handleViewClick = () => {}) => {
  const CustomCell = ({ value }) => {
    return (
      <div title={value || "No data available"}>
        {value
          ? value.length > 20
            ? `${value.slice(0, 20)}...`
            : value
          : "N/A"}
      </div>
    );
  };

  const columns = [
    {
      name: "TR-ID",
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => (
        <PrimaryText
          style={{ cursor: "pointer", textDecoration: "none" }}
          onClick={() => handleViewClick(row.id, "tr-id")}
        >
          {row.id}
        </PrimaryText>
      ),
      width: "100px",
    },

    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
      sortable: true,
      cell: (row) => <CustomCell value={row.patient_name} />,
    },
    {
      name: "Hospital",
      selector: (row) => row.hospital,
      sortable: true,
      cell: (row) => <CustomCell value={row.hospital} />,
    },
    {
      name: "MO Stage",
      selector: (row) => row.medical_opinion_status,
      sortable: true,
    },
    {
      name: "VIL Stage",
      selector: (row) => row.vil_status,
      sortable: true,
    },
    {
      name: "FT Stage",
      selector: (row) => row.flight_ticket_status,
      sortable: true,
    },
    {
      name: "Pre-Intimation",
      selector: (row) => row.preintmation_status,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => row.created_date + " " + row.created_time,
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row) => row.modified_date + " " + row.modified_time,
      sortable: true,
    },
    {
      // name: "Action",
      cell: (row) => (
        <IconButton icon={<HiEye />} onClick={() => handleViewClick(row.id)} />
      ),
      ignoreRowClick: true,
      width: "70px",
      // allowOverflow: true,
      // button: true,
    },
  ];

  return columns;
};

export default DataTableColumns;
