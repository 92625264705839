import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Stack,
  Heading,
  Button,
  VStack,
  Avatar,
  Flex,
  HStack,
  Input,
  InputRightElement,
  IconButton,
  InputGroup,
  Wrap,
} from "@chakra-ui/react";
import { IoSend, IoAttach } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  conversationRequest,
  fetchMessagesHistoryRequest,
} from "./store/actions";
import chatbotIcon from "../../assets/images/chatbot.png";
import { ThreeDots } from "react-loader-spinner";
import { IoClose } from "react-icons/io5";
import AttachmentViewModal from "./AttachmentViewModal";
import { IoMdClose } from "react-icons/io";

const ChatBot = ({
  openChat,
  selectedTaskId,
  newChat,
  setNewChat,
  setSelectedTaskId,
  openMessageHistory,
  isSmallScreen,
  handleCloseChat = () => {},
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const {
    initialMessage,
    botUserResponse,
    singleMessageHistory,
    loading,
    botUserResponseStatus,
  } = useSelector((state) => state.messages);

  const [conversation, setConversation] = useState([]);
  const [existingConversation, setExistingConversation] = useState(false);
  const [taskId, setTaskId] = useState(selectedTaskId);
  const [userTextInput, setUserTextInput] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [rating, setRating] = useState(null);

  useEffect(() => {
    setTaskId(selectedTaskId);
  }, [selectedTaskId]);

  useEffect(() => {
    const payload = { is_start_conversation: true };
    dispatch(conversationRequest(payload));
  }, [dispatch]);

  useEffect(() => {
    if (singleMessageHistory?.comments?.length > 0 && openChat) {
      setConversation(singleMessageHistory.comments);
      setSelectedFile([]);
      setUserTextInput("");
      setExistingConversation(true);
    } else {
      setConversation([]);
      setExistingConversation(false);
    }
  }, [singleMessageHistory, openChat]);

  useEffect(() => {
    if (newChat) {
      setExistingConversation(false);
      setConversation([]);
      setSelectedFile([]);
      setUserTextInput("");
      setTaskId(null);
      let payload = { is_start_conversation: true };
      dispatch(conversationRequest(payload));
      // payload = { is_summary: true };
      // dispatch(fetchMessagesHistoryRequest(payload));
    }
  }, [newChat, dispatch]);

  useEffect(() => {
    if (initialMessage && initialMessage.question && openChat === false) {
      setNewChat(false);
      setTaskId(null);
      setCurrentQuestion(initialMessage.question);
      setConversation(() => [
        {
          user_type: "BOT",
          message: initialMessage.question,
          options: initialMessage.options,
          answer_type: initialMessage.answer_type,
          next_question: initialMessage?.next_question,
          patient_details: botUserResponse?.patient_details,
          // created_at: new Date().toLocaleString("en-US", {
          //   hour: "numeric",
          //   minute: "numeric",
          //   hour12: true,
          // }),
        },
      ]);
    }
  }, [initialMessage, openChat]);

  useEffect(() => {
    if (botUserResponse && botUserResponse.question) {
      setCurrentQuestion(botUserResponse.question);
      if (botUserResponseStatus.status !== 200) {
        setConversation((prev) => [
          ...prev,
          {
            user_type: "BOT",
            message: botUserResponseStatus.message,
            // created_at: new Date().toLocaleString("en-US", {
            //   hour: "numeric",
            //   minute: "numeric",
            //   hour12: true,
            // }),
          },
        ]);
      }
      if (!taskId) {
        if (botUserResponse.task_id) {
          setSelectedTaskId(botUserResponse.task_id);
        }
        const payload = { is_summary: true };
        dispatch(fetchMessagesHistoryRequest(payload));
      }
      setConversation((prev) => [
        ...prev,
        {
          user_type: "BOT",
          message: botUserResponse.question,
          options: botUserResponse.options,
          answer_type: botUserResponse.answer_type,
          next_question: botUserResponse?.next_question,
          patient_details: botUserResponse?.patient_details,
          // created_at: new Date().toLocaleString("en-US", {
          //   hour: "numeric",
          //   minute: "numeric",
          //   hour12: true,
          // }),
        },
      ]);
      if (botUserResponse.task_id) {
        setTaskId(botUserResponse.task_id);
      }
    }
  }, [botUserResponse]);

  const handleOptionClick = (option) => {
    setConversation((prev) => [
      ...prev,
      {
        user_type: "HCF",
        message: option.option,
        // created_at: new Date().toLocaleString("en-US", {
        //   hour: "numeric",
        //   minute: "numeric",
        //   hour12: true,
        // }),
      },
    ]);

    if (option) {
      let payload = new FormData();
      payload.append("is_start_conversation", false);
      payload.append("user_input", option.option);
      payload.append("is_tr_id", 0);
      payload.append("current_question_id", currentQuestion);
      payload.append("next_question_id", option.next_question);

      if (taskId) {
        payload.append("task_id", taskId);
      }
      dispatch(conversationRequest(payload));
    }
  };

  const handleTextSubmit = () => {
    if (userTextInput.trim() || selectedFile.length > 0) {
      setConversation((prev) => [
        ...prev,
        {
          user_type: "HCF",
          message: userTextInput,
          attached_files: selectedFile ? [...selectedFile] : [],
          // created_at: new Date().toLocaleString("en-US", {
          //   hour: "numeric",
          //   minute: "numeric",
          //   hour12: true,
          // }),
        },
      ]);
      const lastEntry = conversation[conversation.length - 1];
      const nextQuestion = lastEntry?.next_question || null;
      let payload = new FormData();
      payload.append("is_start_conversation", false);
      payload.append("user_input", userTextInput);
      payload.append(
        "is_tr_id",
        lastEntry.message === "Please share patient TR ID" ? 1 : 0
      );
      payload.append("current_question_id", currentQuestion);
      payload.append("next_question_id", nextQuestion);

      if (selectedFile.length > 0) {
        selectedFile.forEach((file) => {
          payload.append("attachments", file);
        });
      }

      if (taskId) {
        payload.append("task_id", taskId);
      }
      dispatch(conversationRequest(payload));
      setUserTextInput("");
      setSelectedFile([]);
    }
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFile((prevFiles) => [...prevFiles, ...files]);
    handleFilesAttached();
  };

  const handleRemoveFile = (index) => {
    setSelectedFile((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmitFiles = () => {
    setModalOpen(false);
  };

  const handleFilesAttached = () => {
    setModalOpen(true);
  };

  const renderRatingButtons = () => (
    <HStack spacing={2} mt={4}>
      <Button colorScheme="red" variant="outline" onClick={() => setRating(1)}>
        01
      </Button>
      <Button
        colorScheme="orange"
        variant="outline"
        onClick={() => setRating(2)}
      >
        02
      </Button>
      <Button
        colorScheme="yellow"
        variant="outline"
        onClick={() => setRating(3)}
      >
        03
      </Button>
      <Button colorScheme="teal" variant="outline" onClick={() => setRating(4)}>
        04
      </Button>
      <Button
        colorScheme="green"
        variant="outline"
        onClick={() => setRating(5)}
      >
        05
      </Button>
    </HStack>
  );

  return (
    <>
      <Box
        bg="#2B67AF"
        color="white"
        p={5}
        width="100%"
        height="60px"
        borderTopRadius="lg"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack spacing={0} align="center" mt="10px" flex="1">
          <Heading size="md" mt="6px">
            MediSupport Chat
          </Heading>
          <Text size="md" mb="10px">
            Get help 24x7
          </Text>
        </Stack>
        {openMessageHistory && isSmallScreen && (
          <IconButton
            color="primary.1"
            size="sm"
            aria-label="Filter"
            fontSize="20px"
            isRound={true}
            variant="solid"
            icon={<IoMdClose />}
            onClick={handleCloseChat}
          />
        )}
      </Box>
      <Box
        p={4}
        pb={4}
        borderWidth="1px"
        // height={"712px"}
        width="100%"
        overflowY="auto"
        flex="1"
      >
        <VStack align="start" spacing={4}>
          {conversation.map((entry, index) => (
            <HStack
              key={index}
              alignSelf={entry.user_type === "BOT" ? "flex-start" : "flex-end"}
            >
              {entry.user_type === "BOT" && (
                <Avatar size="md" name="medijournbot" src={chatbotIcon} />
              )}
              <VStack align="stretch">
                {entry.patient_details && entry.patient_details.length > 0 && (
                  <Box
                    p={3}
                    bg={entry.user_type === "BOT" ? "#AFE1AF" : ""}
                    borderRadius="lg"
                    // width="100%"
                  >
                    {entry.patient_details.map((detail, idx) => (
                      <Box key={idx} mb={2}>
                        <Text fontWeight="bold">Patient ID:</Text>
                        <Text>{detail.id}</Text>
                        <Text fontWeight="bold">Name:</Text>
                        <Text>{detail.patient_name}</Text>
                        <Text fontWeight="bold">Country:</Text>
                        <Text>{detail.patient_nationality}</Text>
                        <Text fontWeight="bold">Hospital:</Text>
                        <Text>{detail.hospital}</Text>
                        <Text fontWeight="bold">Health Concern:</Text>
                        <Text>{detail.health_concern}</Text>
                      </Box>
                    ))}
                  </Box>
                )}

                <Box
                  key={index}
                  bg={entry.user_type === "BOT" ? "gray.200" : "blue.100"}
                  borderRadius="lg"
                  p={3}
                  // width="100%"
                >
                  <Text
                    fontWeight="bold"
                    color={entry.user_type === "BOT" ? "blue.500" : "black"}
                  >
                    {entry.message}
                  </Text>
                  {entry.attached_files && entry.attached_files.length > 0 && (
                    <Box mt={2}>
                      {entry.attached_files.map((file, idx) => {
                        const fileType = file.type;
                        if (!existingConversation) {
                          if (fileType.startsWith("image/")) {
                            return (
                              <img
                                key={idx}
                                src={URL.createObjectURL(file)}
                                title={`attachment-${idx}`}
                                width="350px"
                                height="300px"
                                style={{
                                  border: "none",
                                  borderRadius: "8px",
                                  marginTop: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  window.open(
                                    URL.createObjectURL(file),
                                    "_blank"
                                  )
                                }
                              />
                            );
                          } else {
                            return (
                              <Box
                                key={idx}
                                bg="gray.100"
                                p={3}
                                borderRadius="8px"
                                mt={2}
                                width="350px"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  window.open(
                                    URL.createObjectURL(file),
                                    "_blank"
                                  )
                                }
                              >
                                <Text color="blue.500" fontWeight="bold">
                                  {file.name}
                                </Text>
                              </Box>
                            );
                          }
                        }
                        if (existingConversation) {
                          const imageUrl = entry.attached_files[idx];
                          const isImage = /\.(jpeg|jpg|gif|png|bmp|webp|svg)$/i.test(
                            imageUrl
                          );
                          return (
                            <Box mt={2}>
                              {isImage ? (
                                <img
                                  key={idx}
                                  src={imageUrl}
                                  width="350px"
                                  height="300px"
                                  style={{
                                    border: "none",
                                    borderRadius: "8px",
                                    marginTop: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    window.open(imageUrl, "_blank")
                                  }
                                />
                              ) : (
                                <Box
                                  key={idx}
                                  bg="gray.100"
                                  p={3}
                                  borderRadius="8px"
                                  mt={2}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    window.open(imageUrl, "_blank")
                                  }
                                >
                                  <Text color="blue.500" fontWeight="bold">
                                    {imageUrl.split("/").pop()}{" "}
                                    {/* Display the file name */}
                                  </Text>
                                </Box>
                              )}
                            </Box>
                          );
                        }
                      })}
                    </Box>
                  )}
                  {entry.created_at && (
                    <Text
                      fontSize="sm"
                      color="gray.500"
                      mt={2}
                      textAlign={"right"}
                    >
                      {entry.created_at}
                    </Text>
                  )}
                </Box>
                {/* {entry.user_type === "BOT" && entry && renderRatingButtons()} */}
              </VStack>
            </HStack>
          ))}
          <Wrap spacing={4} width={{ base: null, md: "72%" }} pl="60px">
            {conversation.length > 0 &&
              conversation[conversation.length - 1].options &&
              conversation[conversation.length - 1].options.map(
                (option, idx) => (
                  <Button
                    key={idx}
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.option}
                  </Button>
                )
              )}
          </Wrap>
          <Flex
            direction="column"
            width="100%"
            // position="sticky"
            // bottom={0}
            // p={4}
          >
            {selectedFile.length > 0 && (
              <VStack align="start" mt={2}>
                {selectedFile.map((file, index) => (
                  <HStack key={index}>
                    <Text fontSize="sm" color="gray.600">
                      {file.name}
                    </Text>
                    <IconButton
                      aria-label="Remove file"
                      icon={<IoClose />}
                      size="xs"
                      onClick={() => handleRemoveFile(index)}
                    />
                  </HStack>
                ))}{" "}
              </VStack>
            )}
          </Flex>
          {loading && (
            <HStack spacing={4} align="center">
              <Avatar size="md" name="medijournbot" src={chatbotIcon} />
              <ThreeDots
                visible={true}
                height="40"
                width="40"
                color="#2B67AF"
                radius="5"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </HStack>
          )}
        </VStack>
      </Box>

      {conversation.length > 0 &&
        (conversation[conversation.length - 1].answer_type === "Text" ||
          conversation[conversation.length - 1].answer_type ===
            "Text/Upload") && (
          <Box p={4} pt="0px" borderWidth="1px">
            <Flex mt={4} width="100%" alignItems="center">
              <InputGroup>
                <Input
                  id="user-input"
                  style={{ height: "50px", fontSize: "16px" }}
                  value={userTextInput}
                  onChange={(e) => setUserTextInput(e.target.value)}
                  placeholder="Type your message..."
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleTextSubmit();
                    }
                  }}
                  autoFocus={true}
                />
                <InputRightElement mt="4px">
                  {conversation[conversation.length - 1].answer_type ===
                    "Text/Upload" && (
                    <>
                      <IconButton
                        aria-label="Attach file"
                        icon={<IoAttach className="primary-color" />}
                        variant="text"
                        fontSize="30px"
                        onClick={handleFileUpload}
                      />
                      <input
                        type="file"
                        multiple="true"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            handleTextSubmit();
                          }
                        }}
                      />
                    </>
                  )}
                  <IconButton
                    mr="50px"
                    aria-label="Send"
                    icon={
                      <IoSend
                        fontSize={"25px"}
                        alignItems={"center"}
                        className="primary-color"
                      />
                    }
                    onClick={handleTextSubmit}
                    variant="text"
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
          </Box>
        )}
      <AttachmentViewModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        files={selectedFile}
        onAddFile={handleFileUpload}
        onDeleteFile={handleRemoveFile}
        onSubmit={handleSubmitFiles}
      />
    </>
  );
};

export default ChatBot;
