import React from "react";
import {
  Box,
  Flex,
  Button,
  useDisclosure,
  Collapse,
  Spacer,
} from "@chakra-ui/react";
import { IoTrashOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import PrimaryHeading from "../../../components/PrimaryHeading";
import FormInput from "../../../components/FormInput";
import FormSelector from "../../../components/FormSelector";
import CollapseIcon from "../../../assets/images/collapse.svg";
import {
  handleSelectChange,
  handleChange,
  handlePassportFileChange,
  handleViewFile,
  removeFile,
} from "../form/utils";
import FormInputFile from "../../../components/FormPassportInputFile";

const PatientAttendantSection = ({
  index,
  name,
  label,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  onDelete,
  isDeleteVisible,
}) => {
  const dispatch = useDispatch();

  const { embassyData, countryData, passportDetails, passportLabel } =
    useSelector((state) => state.hcf);

  const { isOpen: isSectionOpen, onToggle: toggleSection } = useDisclosure({
    defaultIsOpen: true,
  });

  const formatLabel = (label) => {
    return label.replace(/\s+/g, "").toLowerCase();
  };

  const formattedLabel = formatLabel(label);

  useEffect(() => {
    if (passportDetails && passportLabel && passportLabel === formattedLabel) {
      setFormData((prevData) => ({
        ...prevData,
        [`${formattedLabel}Name`]: passportDetails.name,
        [`${formattedLabel}PassportNumber`]: passportDetails.passport_number,
        [`${formattedLabel}Nationality`]: countryData.find(
          (c) => c.value === passportDetails.nationality
        ),
      }));
      setFormErrors((prevData) => ({
        ...prevData,
        [`${formattedLabel}Name`]: false,
        [`${formattedLabel}PassportNumber`]: false,
        [`${formattedLabel}Nationality`]: false,
      }));
    }
  }, [passportDetails]);

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className="collapse-section-header"
      >
        <PrimaryHeading style={{ margin: 0 }}>{name} Details</PrimaryHeading>
        <img
          src={CollapseIcon}
          width="24px"
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            transition: "transform 0.3s ease",
            transform: isSectionOpen ? "rotate(0)" : "rotate(180deg)",
          }}
          onClick={toggleSection}
        />
        <Spacer />
        {isDeleteVisible && label !== "Patient" && (
          <Button
            leftIcon={<IoTrashOutline style={{ fontSize: "15px" }} />}
            colorScheme="red"
            variant="ghost"
            onClick={() => onDelete(index)}
            aria-label="Delete item"
            size="sm"
          >
            Delete
          </Button>
        )}
      </Flex>
      <Collapse in={isSectionOpen} animateOpacity>
        <Box
          display="flex"
          flexDirection="column"
          bgColor="white"
          borderRadius="10px"
        >
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            className="form-box"
          >
            <FormInputFile
              name="Upload Passport"
              label={label}
              formattedLabel={formattedLabel}
              formData={formData}
              setFormData={setFormData}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              dispatch={dispatch}
              handlePassportFileChange={handlePassportFileChange}
              handleViewFile={handleViewFile}
              removeFile={removeFile}
            />
          </Box>
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            className="form-box"
          >
            <FormInput
              id={`${formattedLabel}Name`}
              label={"Full Name"}
              type="text"
              placeholder={"Enter Full Name"}
              value={formData[`${formattedLabel}Name`]}
              onChange={(e) =>
                handleChange(
                  formData,
                  setFormData,
                  formErrors,
                  setFormErrors,
                  e
                )
              }
              isInvalid={!!formErrors[`${formattedLabel}Name`]}
              errorMessage={formErrors[`${formattedLabel}Name`]}
              isRequired={true}
              flex={{ base: "100%", md: "calc(50% - 10px)" }}
              mr={{ base: "0", md: "20px" }}
            />

            <FormInput
              id={`${formattedLabel}PassportNumber`}
              label="Passport Number"
              type="text"
              placeholder="Enter Passport Number"
              value={formData[`${formattedLabel}PassportNumber`]}
              onChange={(e) =>
                handleChange(
                  formData,
                  setFormData,
                  formErrors,
                  setFormErrors,
                  e
                )
              }
              isInvalid={!!formErrors[`${formattedLabel}PassportNumber`]}
              errorMessage={formErrors[`${formattedLabel}PassportNumber`]}
              isRequired={true}
              flex={{ base: "100%", md: "calc(50% - 10px)" }}
              mr={{ base: "0", md: "0px" }}
            />
          </Box>

          <>
            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              justifyContent="space-between"
              className="form-box"
            >
              <FormSelector
                id={`${formattedLabel}Embassy`}
                label="Embassy"
                options={embassyData}
                placeholder="Select Embassy"
                value={formData[`${formattedLabel}Embassy`]}
                onChange={(selectedOption, actionMeta) =>
                  handleSelectChange(
                    formData,
                    setFormData,
                    formErrors,
                    setFormErrors,
                    selectedOption,
                    actionMeta
                  )
                }
                isInvalid={!!formErrors[`${formattedLabel}Embassy`]}
                errorMessage={formErrors[`${formattedLabel}Embassy`]}
                isRequired={true}
                flex={{ base: "100%", md: "calc(50% - 10px)" }}
                mr={{ base: "0", md: "20px" }}
              />

              <FormSelector
                id={`${formattedLabel}Nationality`}
                label="Nationality"
                options={countryData}
                placeholder="Select Nationality"
                value={formData[`${formattedLabel}Nationality`]}
                onChange={(selectedOption, actionMeta) =>
                  handleSelectChange(
                    formData,
                    setFormData,
                    formErrors,
                    setFormErrors,
                    selectedOption,
                    actionMeta
                  )
                }
                isInvalid={!!formErrors[`${formattedLabel}Nationality`]}
                errorMessage={formErrors[`${formattedLabel}Nationality`]}
                isRequired={true}
                flex={{ base: "100%", md: "calc(50% - 10px)" }}
                mr={{ base: "0", md: "0px" }}
              />
            </Box>
          </>
        </Box>
      </Collapse>
    </>
  );
};

export default PatientAttendantSection;
