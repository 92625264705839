import {
  FiHome,
  FiTrendingUp,
  FiBarChart,
  FiSettings,
  FiUser,
  FiShield,
} from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";

// const allLinkItems = [
//   { name: "Home", icon: FiHome, url: "/home" },
// ];

export const hcfItems = [
  { name: "Home", icon: FiHome, url: "/h/home", subItems: [] },
  { name: "Dashboard", icon: RxDashboard, url: "/h/dashboard", subItems: [] },
  // { name: "Reports", icon: FiTrendingUp, url: "/h/reports", subItems: [] },
];

export const adminLinkItems = [
  { name: "Admin Panel", icon: FiTrendingUp, url: "/admin", subItems: [] },
  { name: "User Management", icon: FiHome, url: "/users", subItems: [] },
];

export const getLinkItemsForRole = (role) => {
  //   let linkItems = [...allLinkItems];
  let linkItems = [];
  console.log("role", role);

  // Add role-specific items
  if (role === "ADMIN") {
    linkItems = [...linkItems, ...adminLinkItems];
  } else if (role === "HCF") {
    linkItems = [...linkItems, ...hcfItems];
  }
  return linkItems;
};
