import React, { useRef, useState } from "react";
import {
  Flex,
  Text,
  Box,
  List,
  ListItem,
  Divider,
  IconButton,
  Icon,
  Input,
} from "@chakra-ui/react";
import PrimaryText from "../../../components/PrimaryText";
import PrimaryButton from "../../../components/PrimaryButton";
import { ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { setTrid } from "../hcfStore/actions";
import { FaPlusCircle } from "react-icons/fa";
import { reportsFileExtensions } from "../constants";

const PatientInfo = ({
  label,
  showButton,
  addFiles,
  name,
  files,
  disabled,
  patient_tr_id,
  reportsModal = () => {},
  handlePassportModal = () => {},
  handleFlightTicketModal = () => {},
  handlePreIntimation = () => {},
  handlePickupDetailsModal = () => {},
}) => {
  const navigate = useNavigate();
  const fileInputRefs = useRef({});
  const [showFiles, setShowFiles] = useState(false);
  const shouldShowList = [
    "Reports",
    "Patient Passport",
    // "Attenders Passport",
    "Medical Opinion",
    "Travel Request",
  ].includes(label);

  const toggleFiles = () => {
    setShowFiles(!showFiles);
  };

  const handleViewFiles = () => {
    if (label === "Pre Intimation") {
      handlePreIntimation();
      return;
    }
    if (label === "Airport Pickup") {
      handlePickupDetailsModal();
      return;
    }
    if (label !== "Flight Tickets" && files.length === 1) {
      handleClick(files[0]);
      return;
    }
    if (
      [
        "Reports",
        // "Attenders Passport",
        "Medical Opinion",
        "Travel Request",
        "Patient Passport",
      ].includes(label)
    ) {
      toggleFiles();
    }

    if (
      label === "Attenders Passport" &&
      name === "View" &&
      files.length !== 0
    ) {
      handleClick(files);
    }
    if (label === "Medical Opinion" && name === "Request") {
      setTrid(patient_tr_id);
      navigate("/medical-opinion");
    }

    if (label === "Flight Tickets") {
      if (name === "Request") {
        setTrid(patient_tr_id);
        navigate("/flight-tickets");
      }
      if (name === "View") {
        handleFlightTicketModal();
      }
    }
    if (label === "Travel Request" && name === "Request") {
      setTrid(patient_tr_id);
      navigate("/travel-request");
    }
  };

  const extractFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  // const handleFileUpload = (event) => {
  //   const files = event.target.files;
  //   if (files.length > 0) {
  //     reportsModal(files);
  //   }
  // };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const validFiles = [];
    const invalidFiles = [];
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    const maxFiles = 25; // Max number of files
    const allowedExtensions = reportsFileExtensions;

    // Ensure total files do not exceed the limit of 25
    if (files.length > maxFiles) {
      alert(`You can only upload up to ${maxFiles} files.`);
      return; // Exit if the user selects more than 25 files
    }

    // Validate file size and extension
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSize = file.size;
      const fileExtension = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();

      if (fileSize <= maxSize && allowedExtensions.includes(fileExtension)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file.name);
      }
    }

    // Show error for invalid files
    if (invalidFiles.length > 0) {
      alert(
        `The following files were not uploaded as they exceed the 10MB limit or have invalid formats: ${invalidFiles.join(
          ", "
        )}`
      );
    }

    // If there are valid files, upload them
    if (validFiles.length > 0) {
      reportsModal(validFiles); // Proceed with valid files
    }
  };

  const handlePassportFileUpload = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const passportFile = files;
      if (handlePassportModal) {
        handlePassportModal(passportFile);
      }
    }
  };

  const handleClick = (file) => {
    if (label === "Patient Passport" || label === "Attenders Passport") {
      if (handlePassportModal && files) {
        handlePassportModal(files);
      }
    } else {
      window.open(file, "_blank");
    }
  };

  return (
    <>
      <Flex align="center" borderRadius="lg">
        <PrimaryText fontWeight="bold" flex="6">
          {label}
        </PrimaryText>
        <Flex
          direction="column"
          ml={2}
          flex="3"
          p={
            label === "Health Concern" || label === "Appointed Doctor"
              ? "14px"
              : "8px"
          }
        >
          {showButton ? (
            <>
              {addFiles && (
                <>
                  <Box
                    top="-8px"
                    right="8px"
                    bg="white"
                    borderRadius="full"
                    p="2px"
                    ml="170px"
                    mb="-14px"
                    zIndex="8"
                  >
                    <Input
                      ref={(el) => (fileInputRefs.current.passport = el)}
                      type="file"
                      multiple={label === "Reports"}
                      position="absolute"
                      width="0.5px"
                      height="10%"
                      opacity="0"
                      cursor="pointer"
                      onChange={
                        label === "Patient Passport" ||
                        label === "Attendants Passport"
                          ? handlePassportFileUpload
                          : handleFileUpload
                      }
                      accept={
                        label === "Patient Passport" ||
                        label === "Attendants Passport"
                          ? ".pdf,.jpg,.jpeg,.png"
                          : label === "Reports"
                          ? reportsFileExtensions
                          : undefined
                      }
                    />
                    <Icon as={FaPlusCircle} boxSize="16px" color="red" />
                  </Box>
                </>
              )}
              <PrimaryButton
                borderRadius="20px"
                onClick={showFiles ? toggleFiles : handleViewFiles}
                isDisabled={disabled}
                // mb="10px"
                bg={
                  name === "Pending"
                    ? "linear-gradient(to right, #747373, #D9D9D9)"
                    : "primary.1"
                }
                position="relative"
                _hover="None"
                p="8px"
              >
                {showFiles ? "Hide" : name}
              </PrimaryButton>
            </>
          ) : (
            <Text fontWeight="bold" flex="4">
              {name}
            </Text>
          )}
        </Flex>
      </Flex>
      {shouldShowList && showFiles && files.length > 1 && (
        <Box mt={2}>
          <List spacing={2}>
            {files.map((file, index) => (
              <ListItem key={index}>
                <Flex align="center">
                  <Text>{extractFileName(file)}</Text>
                  <IconButton
                    variant="ghost"
                    colorScheme="blue"
                    size="sm"
                    aria-label="View"
                    icon={<ViewIcon />}
                    // onClick={() => window.open(file, "_blank")}
                    onClick={() => handleClick(file)}
                    ml="15px"
                  />
                </Flex>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      {label !== "Airport Pickup" && <Divider />}
    </>
  );
};

export default PatientInfo;
