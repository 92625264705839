import { put, all, takeLatest } from "redux-saga/effects";

import {
  HIDE_LOADER,
  SHOW_LOADER,
  apiError,
  exceptionAPIError,
  exceptionError,
  exceptionSessionExpire,
} from "../../authentication/authStore/actions";

import api from "../../../config/api";
import {
  CONVERSATION_REQUEST,
  FETCH_MESSAGES_HISTORY_REQUEST,
  HIDE_MESSAGE_LOADER,
  SHOW_MESSAGE_LOADER,
  fetchAllMessagesSummarySuccess,
  fetchSingleMessageHistorySuccess,
  postUserResponseSuccess,
  startConversationSuccess,
} from "./actions";

function* conversationWorker({ payload }) {
  // yield put({ type: SHOW_LOADER });
  let response;
  try {
    if (payload.is_start_conversation) {
      var data = {};
      response = yield api.get("qms.api.chatbot_support", data, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    } else {
      yield put({ type: SHOW_MESSAGE_LOADER });
      response = yield api.post("qms.api.chatbot_support", payload, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    }
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (payload.is_start_conversation) {
          yield put(startConversationSuccess(response.data));
        } else {
          yield put(postUserResponseSuccess(response));
        }
      } else {
        if (payload.is_start_conversation) {
          yield put(apiError(response));
        } else {
          yield put(postUserResponseSuccess(response));
        }
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_MESSAGE_LOADER });
  }
}

function* messagesHistoryDataWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  let response;
  try {
    if (payload.is_summary) {
      var data = {};
      if (payload.is_filter) {
        response = yield api.post("qms.api.fetch_chat_history", payload, {
          headers: {
            Authorization: "token " + localStorage.getItem("token"),
          },
        });
      } else {
        response = yield api.post("qms.api.fetch_chat_history", data, {
          headers: {
            Authorization: "token " + localStorage.getItem("token"),
          },
        });
      }
    } else {
      response = yield api.post("qms.api.fetch_chat_history", payload, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    }
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (payload.is_summary) {
          yield put(fetchAllMessagesSummarySuccess(response.data));
        } else {
          yield put(fetchSingleMessageHistorySuccess(response.data));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}
export default function* messagesSaga() {
  yield all([takeLatest(CONVERSATION_REQUEST, conversationWorker)]);
  yield all([
    takeLatest(FETCH_MESSAGES_HISTORY_REQUEST, messagesHistoryDataWorker),
  ]);
}
