import React from "react";
import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Box,
} from "@chakra-ui/react";
import PrimaryHeading from "../../../components/PrimaryHeading";

const DashboardCard = ({ heading, data, onClick }) => {
  return (
    <Box pb="40px" ml="11px">
      <PrimaryHeading as="h2" size="lg" mb={4}>
        {heading}
      </PrimaryHeading>
      <SimpleGrid
        spacing={4}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
      >
        {data &&
          data.map((item, index) => (
            <Card
              cursor={item.value > 0 ? "pointer" : null}
              key={index}
              onClick={() => {
                if (item.value > 0) {
                  onClick(item.filter, item.status, data, item.label);
                }
              }}
              bg={item.value > 0 ? "#F9FAFF" : "#E0E0E0"}
              _hover={
                item.value > 0
                  ? {
                      transform: "scale(1.03)",
                      transition: "transform 0.3s ease-in-out",
                      boxShadow: "xl",
                      bg: "primary.1",
                      color: "white",
                    }
                  : {}
              }
            >
              <CardBody>
                <Heading size="md">{item.label}</Heading>
              </CardBody>
              <CardHeader>
                <Heading>{item.value}</Heading>
              </CardHeader>
            </Card>
          ))}
      </SimpleGrid>
    </Box>
  );
};

export default DashboardCard;
