import { Button } from "@chakra-ui/react";
import React from "react";

const TertiaryButton = (props) => {
  return (
    <Button
      // w="33%"
      color={"primary.1"}
      cursor="pointer"
      variant="outline"
      colorScheme="blue"
      {...props}
    />
  );
};

export default TertiaryButton;
