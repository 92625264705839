import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Box,
  ModalFooter,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import Iframe from "react-iframe";
import PrimaryText from "../../../components/PrimaryText";
import PrimaryButton from "../../../components/PrimaryButton";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import FormInput from "../../../components/FormInput";

const FlightTicketsModal = ({ isOpen, onClose, handleSubmit, trDetails }) => {
  const [initialData, setInitialData] = useState({});
  const [currentTicketIndex, setCurrentTicketIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const flightTickets = trDetails?.flight_ticket_details?.flight_tickets || [];

  useEffect(() => {
    if (isOpen && trDetails) {
      const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(":");
        return `${hours}:${minutes}`;
      };
      const initialValues = {
        patient_name: trDetails.patient_name || "",
        airline_details: trDetails.flight_ticket_details?.airline_details || "",
        flight_number: trDetails.flight_ticket_details?.flight_number || "",
        arrival_date: trDetails.flight_ticket_details?.arrival_date || "",
        arrival_time: trDetails.flight_ticket_details?.arrival_time
          ? formatTime(trDetails.flight_ticket_details.arrival_time)
          : "",
        from_country: trDetails.flight_ticket_details?.from_country || "",
        to_country: trDetails.flight_ticket_details?.to_country || "",
        from_airport: trDetails.flight_ticket_details?.from_airport || "",
        to_airport: trDetails.flight_ticket_details?.to_airport || "",
      };
      setInitialData(initialValues);
      setFormData(initialValues);
    }
  }, [isOpen, trDetails]);

  const onSubmit = () => {
    onClose();
  };

  const handleNext = () => {
    if (currentTicketIndex < flightTickets.length - 1) {
      setCurrentTicketIndex(currentTicketIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentTicketIndex > 0) {
      setCurrentTicketIndex(currentTicketIndex - 1);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent maxH="90vh" overflowY="scroll">
        <ModalHeader color="primary.1" textAlign={"center"} fontSize={"2xl"}>
          Flight Tickets
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {flightTickets.length > 0 && (
            <>
              <Flex align="center" justify="center" mb={4}>
                <IconButton
                  icon={<ChevronLeftIcon />}
                  onClick={handlePrevious}
                  isDisabled={currentTicketIndex === 0}
                  mr={2}
                />
                <Iframe
                  height="250px"
                  width="100%"
                  src={flightTickets[currentTicketIndex]}
                  alt={`Flight Ticket ${currentTicketIndex + 1}`}
                />
                <IconButton
                  icon={<ChevronRightIcon />}
                  onClick={handleNext}
                  isDisabled={currentTicketIndex === flightTickets.length - 1}
                  ml={2}
                />
              </Flex>
            </>
          )}
          <Box mt="10px">
            <PrimaryText className="modal-text">Patient Name</PrimaryText>
            <FormInput
              name="patient_name"
              id="patient_name"
              value={formData.patient_name}
              placeholder="Full Name"
              variant="filled"
              // disabled={true}
              isReadOnly={true}
            />
            {/* <Input
              name="patient_name"
              value={formData.patient_name}
              placeholder="Full Name"
              variant="filled"
              // disabled={true}
              isReadOnly={true}
            /> */}
          </Box>
          <Box>
            <PrimaryText className="modal-text">Airlines Detail</PrimaryText>
            <FormInput
              id="airline_details"
              name="airline_details"
              value={formData.airline_details}
              placeholder="Airline Details"
              variant="filled"
              isReadOnly={true}
            />
          </Box>
          <Box>
            <PrimaryText className="modal-text">Flight Number</PrimaryText>
            <FormInput
              name="flight_number"
              id="flight_number"
              value={formData.flight_number}
              placeholder="Flight Number"
              variant="filled"
              isReadOnly={true}
            />
          </Box>
          <Box>
            <PrimaryText className="modal-text">Arrival Date</PrimaryText>
            <FormInput
              id="arrival_date"
              name="arrival_date"
              type="date"
              value={formData.arrival_date}
              placeholder="Arrival Date"
              variant="filled"
              isReadOnly={true}
            />
          </Box>
          <Box>
            <PrimaryText className="modal-text">Arrival Time</PrimaryText>
            <FormInput
              name="arrival_time"
              id="arrival_time"
              type="time"
              value={formData.arrival_time}
              placeholder="Arrival Time"
              variant="filled"
              isReadOnly={true}
            />
          </Box>
          <Box>
            <PrimaryText className="modal-text">From Country</PrimaryText>
            <FormInput
              id="fromCountry"
              name="from_country"
              value={formData.from_country}
              placeholder="From Country"
              variant="filled"
              // disabled={true}
              isReadOnly={true}
            />
          </Box>
          <Box>
            <PrimaryText className="modal-text">To Country</PrimaryText>
            <FormInput
              id="toCountry"
              name="to_country"
              value={formData.to_country}
              placeholder="To Country"
              variant="filled"
              isReadOnly={true}
            />
          </Box>
          <Box>
            <PrimaryText className="modal-text">From Airport</PrimaryText>
            <FormInput
              id="fromAirport"
              name="from_airport"
              value={formData.from_airport}
              placeholder="From Airport"
              variant="filled"
              isReadOnly={true}
            />
          </Box>
          <Box mb={4}>
            <PrimaryText className="modal-text">To Airport</PrimaryText>
            <FormInput
              id="toAirport"
              name="toAirport"
              value={formData.to_airport}
              placeholder="To Airport"
              variant="filled"
              isReadOnly={true}
            />
          </Box>
        </ModalBody>
        {/* <ModalFooter>
          <PrimaryButton colorScheme="blue" ml="auto" onClick={onSubmit}>
            Close
          </PrimaryButton>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default FlightTicketsModal;
