import React, { useRef, useState } from "react";
import {
  Box,
  Flex,
  Button,
  Checkbox,
  useDisclosure,
  Collapse,
  Spacer,
} from "@chakra-ui/react";
import { IoTrashOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import PrimaryHeading from "../../../components/PrimaryHeading";
import FormInput from "../../../components/FormInput";
import FormSelector from "../../../components/FormSelector";
import CollapseIcon from "../../../assets/images/collapse.svg";
import {
  handleSelectChange,
  handleChange,
  handlePassportFileChange,
  handleViewFile,
  removeFile,
} from "../form/utils";
import FormInputFile from "../../../components/FormPassportInputFile";
import PrimaryFormLabel from "../../../components/PrimaryFormLabel";
import FlightTicketForm from "./FlightTicketForm";

const PatientAttendantForm = ({
  index,
  name,
  label,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  onDelete,
  isDeleteVisible,
  isAutopopulateData = false,
  autoPopulatedFields = [],
  disableDelete = false,
}) => {
  const dispatch = useDispatch();
  const [maxDate, setMaxDate] = useState();
  const [selectedFile, setSelectedFile] = useState([]);
  const fileInputRef = useRef(null);

  const { isOpen: isSectionOpen, onToggle: toggleSection } = useDisclosure({
    defaultIsOpen: true,
  });

  const { countryData, passportDetails, passportLabel } = useSelector(
    (state) => state.hcf
  );

  const formatLabel = (label) => {
    return label.replace(/\s+/g, "").toLowerCase();
  };

  const formattedLabel = formatLabel(label);

  const isDisabled = (field) => {
    return isAutopopulateData && autoPopulatedFields[field];
  };

  useEffect(() => {
    if (passportDetails && passportLabel && passportLabel === formattedLabel) {
      setFormData((prevData) => {
        const newFormData = {
          ...prevData,
          [`${formattedLabel}Name`]: passportDetails.name,
          [`${formattedLabel}PassportNumber`]: passportDetails.passport_number,
        };
        if (passportLabel === "patient") {
          const formattedDob = passportDetails.dob
            .split("-")
            .reverse()
            .join("-");
          newFormData[`${formattedLabel}Dob`] = formattedDob;
          newFormData[`${formattedLabel}Nationality`] = countryData.find(
            (c) => c.value === passportDetails.nationality
          );
        }
        return newFormData;
      });
      setFormErrors((prevErrors) => {
        const newErrors = {
          ...prevErrors,
          [`${formattedLabel}Name`]: false,
          [`${formattedLabel}PassportNumber`]: false,
        };

        if (passportLabel === "patient") {
          newErrors[`${formattedLabel}Dob`] = false;
          newErrors[`${formattedLabel}Nationality`] = false;
        }

        return newErrors;
      });
    }
  }, [passportDetails]);

  useEffect(() => {
    if (formData.healthConcern !== "PEDIATRIC CARDIAC SURGERY") {
      const currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      setMaxDate(currentDate.toISOString().split("T")[0]);
    } else {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 2);
      setMaxDate(currentDate.toISOString().split("T")[0]);
    }
  }, [formData.healthConcern]);

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [`${formattedLabel}SameFlightticketAsPatient`]: checked,
    }));
  };

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className="collapse-section-header"
      >
        <PrimaryHeading>{name} Details</PrimaryHeading>
        <img
          src={CollapseIcon}
          width="24px"
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            transition: "transform 0.3s ease",
            transform: isSectionOpen ? "rotate(0)" : "rotate(180deg)",
          }}
          onClick={toggleSection}
        />
        <Spacer />
        {isDeleteVisible && label !== "Patient" && (
          <Button
            leftIcon={<IoTrashOutline style={{ fontSize: "15px" }} />}
            colorScheme="red"
            variant="ghost"
            onClick={() => onDelete(index)}
            isDisabled={disableDelete}
            aria-label="Delete item"
            size="sm"
          >
            Delete
          </Button>
        )}
      </Flex>
      <Collapse in={isSectionOpen} animateOpacity>
        <Box
          mt="10px"
          display="flex"
          flexDirection="column"
          bgColor="white"
          // p="15px 20px"
          borderRadius="10px"
          // mb="20px"
        >
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            className="form-box"
            // mb="20px"
          >
            <FormInputFile
              name="Upload Passport"
              label={label}
              formattedLabel={formattedLabel}
              formData={formData}
              setFormData={setFormData}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              dispatch={dispatch}
              handlePassportFileChange={handlePassportFileChange}
              handleViewFile={handleViewFile}
              removeFile={removeFile}
              disabled={isDisabled(`${formattedLabel}Passport`)}
            />
          </Box>
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            className="form-box"
          >
            <FormInput
              id={`${formattedLabel}Name`}
              // label="Name"
              label={"Full Name"}
              type="text"
              placeholder={"Enter Full Name"}
              value={formData[`${formattedLabel}Name`]}
              onChange={(e) =>
                handleChange(
                  formData,
                  setFormData,
                  formErrors,
                  setFormErrors,
                  e
                )
              }
              isInvalid={!!formErrors[`${formattedLabel}Name`]}
              errorMessage={formErrors[`${formattedLabel}Name`]}
              isRequired={true}
              // flex={{ base: "100%", md: "calc(50% - 10px)" }}
              flex="50%"
              mr={{ base: "0", md: "20px" }}
              disabled={isDisabled(`${formattedLabel}Name`)}
            />

            <FormInput
              id={`${formattedLabel}PassportNumber`}
              label="Passport Number"
              type="text"
              placeholder="Enter Passport Number"
              value={formData[`${formattedLabel}PassportNumber`]}
              onChange={(e) =>
                handleChange(
                  formData,
                  setFormData,
                  formErrors,
                  setFormErrors,
                  e
                )
              }
              isInvalid={!!formErrors[`${formattedLabel}PassportNumber`]}
              errorMessage={formErrors[`${formattedLabel}PassportNumber`]}
              isRequired={true}
              flex="50%"
              mr={{ base: "0", md: "0px" }}
              disabled={isDisabled(`${formattedLabel}PassportNumber`)}
            />
          </Box>
          {label === "Patient" && (
            <>
              <Box
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                justifyContent="space-between"
                className="form-box"
              >
                <FormInput
                  id={`${formattedLabel}Dob`}
                  label="D.O.B"
                  type="date"
                  placeholder="Enter Dob"
                  value={formData[`${formattedLabel}Dob`]}
                  onChange={(e) =>
                    handleChange(
                      formData,
                      setFormData,
                      formErrors,
                      setFormErrors,
                      e
                    )
                  }
                  disabled={isDisabled(`${formattedLabel}Dob`)}
                  isInvalid={!!formErrors[`${formattedLabel}Dob`]}
                  errorMessage={formErrors[`${formattedLabel}Dob`]}
                  isRequired={true}
                  flex={{ base: "100%", md: "calc(50% - 10px)" }}
                  mr={{ base: "0", md: "20px" }}
                  name="dob"
                  maxDate={maxDate}
                />
                <FormSelector
                  id={`${formattedLabel}CountryOfRes`}
                  label="Country Of Residency"
                  options={countryData}
                  placeholder="Select Country Of Residency"
                  value={formData[`${formattedLabel}CountryOfRes`]}
                  onChange={(selectedOption, actionMeta) =>
                    handleSelectChange(
                      formData,
                      setFormData,
                      formErrors,
                      setFormErrors,
                      selectedOption,
                      actionMeta
                    )
                  }
                  disabled={isDisabled(`${formattedLabel}CountryOfRes`)}
                  isInvalid={!!formErrors[`${formattedLabel}CountryOfRes`]}
                  errorMessage={formErrors[`${formattedLabel}CountryOfRes`]}
                  isRequired={true}
                  flex={{ base: "100%", md: "calc(50% - 10px)" }}
                  mr={{ base: "0", md: "0px" }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                justifyContent="space-between"
                className="form-box"
                w={{ base: "100%", md: "calc(50% + 20px)" }}
              >
                <FormSelector
                  id="patientNationality"
                  label="Nationality"
                  options={countryData}
                  placeholder="Select Patient Nationality"
                  value={formData.patientNationality}
                  onChange={(selectedOption, actionMeta) =>
                    handleSelectChange(
                      formData,
                      setFormData,
                      formErrors,
                      setFormErrors,
                      selectedOption,
                      actionMeta
                    )
                  }
                  disabled={isDisabled(`${formattedLabel}Nationality`)}
                  isInvalid={!!formErrors.patientNationality}
                  errorMessage={formErrors.patientNationality}
                  isRequired={true}
                  flex={{ base: "100%", md: "calc(50% - 10px)" }}
                  mr={{ base: "0", md: "10px" }}
                />
              </Box>
            </>
          )}
          {label !== "Patient" && (
            <Checkbox
              className="form-checkbox"
              color={"primary.1"}
              // iconSize="3rem"
              size="lg"
              // colorScheme="primary.1"
              isChecked={formData[`${formattedLabel}SameFlightticketAsPatient`]}
              onChange={handleCheckboxChange}
            >
              <PrimaryFormLabel mt="8px">
                Travelling on the same flight as patient
              </PrimaryFormLabel>
            </Checkbox>
          )}
          {label === "Patient" ||
          !formData[`${formattedLabel}SameFlightticketAsPatient`] ? (
            <FlightTicketForm
              formattedLabel={formattedLabel}
              formData={formData}
              setFormData={setFormData}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              // handleReportsFileChange={handleReportsFileChange}
              // handleViewFile={handleViewFile}
              // removeFile={removeFile}
              fileInputRef={fileInputRef}
            />
          ) : null}
        </Box>
      </Collapse>
    </>
  );
};

export default PatientAttendantForm;
