import React from "react";
import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Select as ChakraSelect } from "chakra-react-select";

import PrimaryFormLabel from "./PrimaryFormLabel";

const FormSelector = ({
  id,
  label,
  options,
  placeholder,
  value,
  onChange,
  isInvalid,
  errorMessage,
  isRequired,
  flex,
  mr,
  disabled = false,
  pt,
  isMulti = false,
  closeMenuOnSelect = true,
  menuPortalTarget = document.body,
}) => {
  return (
    <FormControl
      className="form-input"
      isInvalid={isInvalid}
      flex={flex}
      mr={mr}
      isRequired={isRequired}
      pt={pt}
    >
      <PrimaryFormLabel htmlFor={id}>{label}</PrimaryFormLabel>
      <ChakraSelect
        isMulti={isMulti}
        id={id}
        name={id}
        placeholder={placeholder}
        options={options}
        menuPortalTarget={menuPortalTarget}
        classNamePrefix="chakra-react-select"
        value={value}
        onChange={onChange}
        isDisabled={disabled}
        closeMenuOnSelect={closeMenuOnSelect}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default FormSelector;
