import React from "react";
import { Flex, Spinner, Center, Text } from "@chakra-ui/react";
// import ReactLoading from "react-loading";
// import { ThreeDot } from "react-loading-indicators";

const LoadingSpinner = ({ ml }) => {
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="9999"
      backgroundColor="rgba(255, 255, 255, 0.5)"
      // backgroundColor="rgba(0, 0, 0, 0.3)"  // use spinner color  color="primary.5"
      // backgroundColor="rgba(120, 120, 120, 0.2)" // use spinner color  color="primary.6"

      justifyContent="center"
      alignItems="center"
    >
      <Center>
        <Flex
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          ml={ml}
        >
          {/* <ReactLoading type="bubbles" color="#174f90" /> */}
          {/* <ThreeDot color="#174f90" size="medium" text="" textColor="" /> */}

          <Spinner
            thickness="6px"
            // speed="0.8s"
            // emptyColor="gray.200"
            size="xl"
            color="primary.1"
          />

          <Text as="b" fontSize="md" color="primary.1">
            Loading...
          </Text>
        </Flex>
      </Center>
    </Flex>
  );
};

export default LoadingSpinner;
