import React, { useState, useEffect } from "react";
import Layout from "../../layout";
import { useSelector, useDispatch } from "react-redux";
import PrimaryHeading from "../../components/PrimaryHeading";
import {
  Box,
  Grid,
  Flex,
  Text,
  Input,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import CollapseIcon from "../../assets/images/collapse.svg";
import PrimaryFormLabel from "../../components/PrimaryFormLabel";
import PrimaryButton from "../../components/PrimaryButton";
import { PasswordField } from "./components/PasswordField";
import {
  fetchProfileDetailsRequest,
  sendOTPRequest,
  verifyOTPRequest,
  verifyUserOTPRequest,
} from "./authStore/actions";
import Alert from "../../components/Alert";
import OtpModal from "./components/OtpModal";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    profileDetails,
    otpSent,
    userOtpResponse,
    currentPasswordMismatch,
  } = useSelector((state) => state.auth);

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isMessageOpen, setMessageOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Success!");
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [incorrectPasswordAlert, setIncorrectPasswordAlert] = useState(false);
  const [otpType, setOtpType] = useState("FP");
  const { isOpen: isSectionOpen, onToggle: toggleSection } = useDisclosure({
    defaultIsOpen: false,
  });

  useEffect(() => {
    if (otpSent) {
      onOpen();
    } else {
      onClose();
    }
  }, [otpSent]);

  useEffect(() => {
    if (currentPasswordMismatch) {
      setIncorrectPasswordAlert(true);
    }
  }, [currentPasswordMismatch]);

  useEffect(() => {
    setAlertTitle("Success!");
    setAlertType("success");
    if (userOtpResponse) {
      if (otpType === "FP") {
        setPassword("");
        setConfirmPassword("");
        setPasswordError("");
      }
      onClose();
      setShowAlert(true);
      setAlertMessage(userOtpResponse.message);
    } else {
      setShowAlert(false);
    }
  }, [userOtpResponse]);

  useEffect(() => {
    let payload = {
      action: "get",
    };
    dispatch(fetchProfileDetailsRequest(payload));
  }, []);

  useEffect(() => {
    if (profileDetails && profileDetails.fullname) {
      setName(profileDetails.fullname);
      setCompany(profileDetails.company);
      setDesignation(profileDetails.designation);
    }
  }, [profileDetails]);

  // useEffect(() => {
  //   if (reset_password) {
  //     setShowAlert(true);
  //     setAlertTitle("Success!");
  //     setAlertType("success");
  //     setAlertMessage("Your password has been successfully changed");
  //   }
  // }, [reset_password]);

  // useEffect(() => {
  //   console.log("postprofileDetails", postprofileDetails);
  //   if (postprofileDetails && postprofileDetails.status === 200) {
  //     setShowAlert(true);
  //     setAlertTitle("Success!");
  //     setAlertType("success");
  //     setAlertMessage(postprofileDetails.message);
  //   }
  // }, [postprofileDetails]);

  const handleEditClick = () => {
    setIsEditing(true);
    setOtpType("Profile");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") setName(value);
    if (name === "company") setCompany(value);
    if (name === "designation") setDesignation(value);
  };

  const handleSaveClick = () => {
    const editedFields = {};
    if (name !== profileDetails.fullname) editedFields.fullname = name;
    if (company !== profileDetails.company) editedFields.company = company;
    if (designation !== profileDetails.designation)
      editedFields.designation = designation;

    if (Object.keys(editedFields).length > 0) {
      let payload = { otp_type: "Profile" };
      dispatch(sendOTPRequest(payload));
      // setIsEditing(false);
    } else {
      setShowAlert(true);
      setAlertTitle("Information!");
      setAlertType("info");
      setAlertMessage("No changes made");
      setIsEditing(false);
    }
  };

  const handlePasswordChange = () => {
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );
    if (!strongRegex.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }
    // const payload = { usr: profileDetails.email, pwd: password };
    // dispatch(resetPasswordRequest(payload));
    let payload = { otp_type: "FP", current_pwd: currentPassword };
    dispatch(sendOTPRequest(payload));
  };

  const handleOtpSubmit = (otp) => {
    let payload = {
      usr: profileDetails.email,
      otp: otp,
      otp_type: otpType,
    };
    if (otpType === "Profile") {
      payload["name"] = name;
      payload["company"] = company;
      payload["designation"] = designation;
      dispatch(verifyUserOTPRequest(payload));
    } else {
      payload["pwd"] = password;
      dispatch(verifyUserOTPRequest(payload));
    }
  };

  return (
    <Layout>
      <Flex justify="space-between" align="center" mb="30px" mt="20px">
        <PrimaryHeading>User Profile</PrimaryHeading>
        {!isEditing && (
          <Box
            cursor="pointer"
            color="blue.600"
            fontSize="20px"
            onClick={handleEditClick}
          >
            <FaEdit />
          </Box>
        )}
      </Flex>
      <Box
        bgColor="white"
        p="20px"
        borderRadius="10px"
        mb="10px"
        id="user_details"
        position="relative"
      >
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4}>
          <Flex flex="1">
            <PrimaryFormLabel mr="60px">Name:</PrimaryFormLabel>
            {!isEditing ? (
              <Text ml={2}>{name}</Text>
            ) : (
              <Input
                ml={2}
                name="name"
                value={name}
                onChange={handleInputChange}
              />
            )}
          </Flex>
          <Flex flex="1">
            <PrimaryFormLabel mr={{ base: "40px", md: "20px" }}>
              Company:
            </PrimaryFormLabel>
            {!isEditing ? (
              <Text>{company}</Text>
            ) : (
              <Input
                name="company"
                value={company}
                onChange={handleInputChange}
              />
            )}
          </Flex>

          <Flex flex="1">
            <PrimaryFormLabel mr="60px">Email:</PrimaryFormLabel>
            {!isEditing ? (
              <Text ml={2}>{profileDetails.email}</Text>
            ) : (
              <Input
                ml={2}
                name="email"
                value={profileDetails.email}
                isReadOnly
              />
            )}
          </Flex>
          <Flex flex="1">
            <PrimaryFormLabel mr={{ base: "50px", md: "30px" }}>
              Mobile:
            </PrimaryFormLabel>
            {!isEditing ? (
              <Text ml={2}>{profileDetails.mobile_no}</Text>
            ) : (
              <Input
                ml={2}
                name="mobile_no"
                value={profileDetails.mobile_no}
                isReadOnly
              />
            )}
          </Flex>
          <Flex flex="1">
            <PrimaryFormLabel mr={{ base: "20px", md: "20px" }}>
              Designation:
            </PrimaryFormLabel>
            {!isEditing ? (
              <Text>{designation}</Text>
            ) : (
              <Input
                name="designation"
                value={designation}
                onChange={handleInputChange}
              />
            )}
          </Flex>
          <Flex flex="1">
            <PrimaryFormLabel mr={{ base: "70px", md: "50px" }}>
              Role:
            </PrimaryFormLabel>
            {!isEditing ? (
              <Text ml={2}>{profileDetails.role}</Text>
            ) : (
              <Input
                ml={2}
                name="designation"
                value={profileDetails.role}
                readOnly
              />
            )}
          </Flex>
        </Grid>
        {isEditing && (
          <Box mt="10px" textAlign="right">
            <PrimaryButton onClick={handleSaveClick} colorScheme="blue">
              Confirm Changes
            </PrimaryButton>
          </Box>
        )}
      </Box>
      <Box>
        <Flex alignItems="center" mt="20px">
          <PrimaryHeading>Change Password</PrimaryHeading>
          <img
            src={CollapseIcon}
            width={"24px"}
            style={{
              cursor: "pointer",
              marginLeft: "10px",
              transition: "transform 0.3s ease",
              transform: isSectionOpen ? "rotate(0)" : "rotate(180deg)",
            }}
            onClick={toggleSection}
          />
        </Flex>
        <Collapse in={isSectionOpen} animateOpacity>
          <Box
            bgColor="white"
            p="20px"
            borderRadius="10px"
            mb="10px"
            id="user_details"
            position="relative"
          >
            <Grid
              templateColumns={{ base: "1fr", md: "1fr 1fr" }}
              gap={4}
              mb="20px"
            >
              <PasswordField
                placeholder="Enter Current password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                error={passwordError}
              />
            </Grid>
            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4}>
              <PasswordField
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError}
              />
              <PasswordField
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={passwordError}
              />
            </Grid>
            {passwordError && (
              <Text mt={2} color="red.500">
                {passwordError}
              </Text>
            )}
            <Box mt="10px" textAlign="right">
              <PrimaryButton
                mt={4}
                colorScheme="blue"
                onClick={handlePasswordChange}
              >
                Update
              </PrimaryButton>
            </Box>
          </Box>
        </Collapse>
      </Box>
      <Alert
        isOpen={showAlert}
        title={alertTitle}
        onClose={() => {
          setShowAlert(false);
        }}
        body={alertMessage}
        type={alertType}
      />
      <Alert
        isOpen={isMessageOpen}
        title="Information!"
        onClose={() => {
          setMessageOpen(false);
          setIsEditing(false);
        }}
        body="No Changes made."
        type="info"
      />
      <Alert
        isOpen={incorrectPasswordAlert}
        title="Incorrect Password"
        onClose={() => setIncorrectPasswordAlert(false)}
        body="The current password you entered is incorrect. Please try again."
        type="error"
      />
      <OtpModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleOtpSubmit}
        user={profileDetails.email}
        otpType={otpType}
      />
    </Layout>
  );
};

export default UserProfile;
