export const genderData = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];

export const patientMovingFromData = [
  { value: "Within Country", label: "Within Country" },
  { value: "Outside Country", label: "Outside Country" },
];

export const arrivalWithinData = [
  { value: "Less than 3 Days", label: "Less than 3 Days" },
  { value: "Less than 7 Days", label: "Less than 7 Days" },
  { value: "Less than 15 Days", label: "Less than 15 Days" },
  { value: "Less than 30 Days", label: "Less than 30 Days" },
  { value: "More than 30 Days", label: "More than 30 Days" },
];

export const Days = [
  { value: "today", label: "Today" },
  { value: "last_week", label: "Last 7 Days" },
  { value: "last_month", label: "Last 30 Days" },
  { value: "custom", label: "Custom" },
];

export const companyTypes = [
  { value: "Company", label: "Company" },
  { value: "Individual", label: "Individual" },
];

export const roleOptions = [
  { value: "doctor", label: "Doctor" },
  { value: "ips", label: "IPS" },
];

export const FlightTicketFreezeFields = {
  patientName: false,
  patientPassport: false,
  patientPassportNumber: false,
  patientDob: false,
  patientCountryOfRes: false,
  patientNationality: false,
  attendantName: false,
  attendantPassportNumber: false,
  attendantPassport: false,
};

export const MedicalOpinionFreezeFields = {
  name: false,
  passport: false,
  passportNumber: false,
  dob: false,
  countryOfResidence: false,
  nationality: false,
  gender: false,
};

export const reportsFileExtensions = [
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  ".doc",
  ".docx",
  ".gif",
  ".dcm",
  ".bmp",
  ".mp4",
  ".avi",
  ".mpeg",
  ".wmv",
  ".mov",
  ".flv",
  ".m2v",
  ".ogv",
  ".xvid",
  ".divx",
  ".hevc",
  ".h265",
  ".vob",
  ".mkv",
  ".webm",
  ".3gp",
  ".ts",
  ".flc",
  ".asf",
  ".dv",
  ".swf",
  ".m2ts",
  ".mxf",
  ".f4v",
  ".rmvb",
  ".h263",
  ".m4v",
  ".mpg4",
  ".m3u8",
  ".3g2",
  ".dat",
  ".mk3d",
  ".ogm",
];
