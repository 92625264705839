import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  RESEND_OTP_ERROR,
  RESEND_OTP_SUCCESS,
  SHOW_LOADER,
  HIDE_LOADER,
  LOGOUT_REQUEST,
  RESEND_OTP_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FETCH_PROFILE_DETAILS_SUCCESS,
  FETCH_PROFILE_DETAILS_ERROR,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  SESSION_EXPIRE_ERROR,
  EXCEPTION_ERROR,
  SESSION_API_ERROR,
  RESET_EXCEPTION_ERROR,
  FETCH_PROFILE_DETAILS_POST_SUCCESS,
  API_ERROR,
  FETCH_DESIGNATION_SUCCESS,
  FETCH_DESIGNATION_ERROR,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  SEND_OTP_REQUEST,
  VERIFY_USER_OTP_REQUEST,
  VERIFY_USER_OTP_SUCCESS,
  VERIFY_USER_OTP_FAILURE,
  RESET_PASSWORD_REQUEST,
  VERIFY_REGISTER_OTP_SUCCESS,
  FETCH_PROFILE_DETAILS_REQUEST,
  FETCH_HOSPITALS_ERROR,
  FETCH_HOSPITALS_SUCCESS,
  RESET_ALL,
} from "./actions";

const initialState = {
  loading: false,
  otp_loading: false,
  isAuthenticated: false,
  is_logged_in: false,
  authUser: {},
  error: false,
  otp_errors: false,
  confirm_email: "",
  resend_success: false,
  fp_otp_sent: false,
  reset_password: false,
  reset_pwd_res: false,
  is_reg: false,
  // rememberMe: false,
  profileDetails: false,
  isInternalServerError: false,
  isSessionAPIError: false,
  isSessionExpiry: false,
  postprofileDetails: false,
  apiError: false,
  apiErrorRes: false,
  designationData: [],
  hospitalsData: [],
  otpSent: false,
  currentPasswordMismatch: false,
  passwordResponse: false,
  profileResponse: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };

    case HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };

    case SESSION_EXPIRE_ERROR:
      return {
        ...state,
        isSessionExpiry: true,
      };
    case EXCEPTION_ERROR:
      return {
        ...state,
        ISError: action.ISError,
        isInternalServerError: true,
      };
    case SESSION_API_ERROR:
      return {
        ...state,
        apiError: action.apiError,
        isInternalServerError: false,
        isSessionAPIError: false,
        isSessionExpiry: false,
      };
    case RESET_ALL:
      return {
        ...initialState,
      };
    case RESET_EXCEPTION_ERROR:
      return {
        ...state,
        ISError: false,
        isInternalServerError: false,
        isSessionAPIError: false,
        apiErrorRes: false,
        apiError: false,
      };
    case API_ERROR:
      return {
        ...state,
        apiErrorRes: action.payload,
        isInternalServerError: false,
        isSessionAPIError: false,
        isSessionExpiry: false,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        is_logged_in: false,
        fp_otp_sent: false,
        error: false,
        token: false,
        otp_errors: false,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        fp_otp_sent: false,
        is_logged_in: false,
        error: action.payload,
        token: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        is_logged_in: true,
        error: false,
        token: action.payload.token,
      };
    case LOGOUT_REQUEST:
      return {
        ...initialState,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        is_reg: false,
        error: false,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        error: action.payload,
        is_reg: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        is_reg: true,
        error: false,
      };
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        otp_loading: true,
        otp_errors: false,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otp_errors: false,
        isAuthenticated: true,
        otp_loading: false,
      };
    case VERIFY_OTP_ERROR:
      return {
        ...state,
        otp_errors: action.payload,
        isAuthenticated: false,
        otp_loading: false,
      };
    case RESEND_OTP_REQUEST:
      return {
        ...state,
        otp_loading: true,
        otp_errors: false,
      };
    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        otp_errors: false,
        resend_success: action.payload,
        otp_loading: false,
      };
    case RESEND_OTP_ERROR:
      return {
        ...state,
        otp_errors: action.payload,
        otp_loading: false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        error: false,
        fp_otp_sent: true,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        error: false,
        fp_otp_sent: false,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        error: action.payload,
        fp_otp_sent: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: false,
        reset_password: true,
        reset_pwd_res: action.payload,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        error: false,
        reset_password: false,
        reset_pwd_res: false,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        error: action.payload,
        reset_password: false,
        reset_pwd_res: false,
      };
    case FETCH_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        otpSent: false,
        userOtpResponse: false,
        profileDetails: false,
        errorResponse: false,
      };
    case FETCH_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        profileDetails: action.payload,
        errorResponse: false,
      };
    case FETCH_PROFILE_DETAILS_ERROR:
      return {
        ...state,
        profileDetails: false,
        errorResponse: action.payload,
      };
    case FETCH_PROFILE_DETAILS_POST_SUCCESS:
      return {
        ...state,
        postprofileDetails: action.payload,
        errorResponse: false,
      };
    case FETCH_DESIGNATION_SUCCESS:
      return {
        ...state,
        designationData: action.payload,
        errorResponse: false,
      };
    case FETCH_DESIGNATION_ERROR:
      return {
        ...state,
        designationData: [],
        errorResponse: action.payload,
      };
    case SEND_OTP_REQUEST:
      return {
        ...state,
        otp_errors: false,
        otpSent: false,
        currentPasswordMismatch: false,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
      };
    case SEND_OTP_FAILURE:
      return {
        ...state,
        otpSent: false,
        currentPasswordMismatch: true,
      };
    case VERIFY_USER_OTP_REQUEST:
      return {
        ...state,
        otp_loading: true,
        userOtpResponse: false,
        otp_errors: false,
      };
    case VERIFY_USER_OTP_SUCCESS:
      return {
        ...state,
        userOtpResponse: action.payload,
        otp_errors: false,
        otp_loading: false,
      };
    case VERIFY_USER_OTP_FAILURE:
      return {
        ...state,
        userOtpResponse: false,
        otp_errors: action.payload,
        otp_loading: false,
      };
    case VERIFY_REGISTER_OTP_SUCCESS:
      return {
        ...state,
        regOtpResponse: action.payload,
        otp_loading: false,
        otp_errors: false,
      };
    case FETCH_HOSPITALS_SUCCESS:
      return {
        ...state,
        hospitalsData: action.payload,
        errorResponse: false,
      };
    case FETCH_HOSPITALS_ERROR:
      return {
        ...state,
        hospitalsData: [],
        errorResponse: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
