import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchRequest } from "./store/actions";
import PatientDetailsModal from "../hcf/components/PatientDetailsModal";
import ReactDataTable from "../../components/PrimaryTable";
import { Box, IconButton } from "@chakra-ui/react";
import { HiEye } from "react-icons/hi";
import { fetchTrDetailsRequest, resetTrdetails } from "../hcf/hcfStore/actions";
import Layout from "../../layout";
import Selector from "./components/Selector";
import SecondaryButton from "../../components/SecondaryButton";
import DataTableColumns from "../../components/DataTableColumns";

const SearchResults = () => {
  const dispatch = useDispatch();

  const { searchSectionResponse } = useSelector((state) => state.search);
  const { trDetails } = useSelector((state) => state.hcf);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    let payload = {
      query: localStorage.getItem("search_query"),
      section: localStorage.getItem("search_section"),
    };
    dispatch(resetTrdetails());
    dispatch(searchRequest(payload));
  }, []);

  useEffect(() => {
    if (trDetails && trDetails.length > 0) {
      setIsModalOpen(true);
    }
  }, [trDetails]);

  const handleViewClick = (id) => {
    let payload = {
      tr_id: id,
    };
    setSelectedPatient(id);
    dispatch(fetchTrDetailsRequest(payload));
  };

  const options_map = {
    "TR-ID": "tr_id",
    "Patient Name": "patient_name",
    "Passport Number": "passport_no",
    Hospitals: "hospital",
    "Health Concern": "health_concern",
    Country: "country",
  };

  const reverse_options_map = {
    tr_id: "TR-ID",
    patient_name: "Patient Name",
    passport_no: "Passport Number",
    hospital: "Hospitals",
    health_concern: "Health Concern",
    country: "Country",
  };

  const options = [
    "TR-ID",
    "Patient Name",
    "Passport Number",
    "Hospitals",
    "Health Concern",
    "Country",
  ];

  const handleOptionSelect = (option) => {
    const section = options_map[option];
    localStorage.setItem("search_section", section);

    let payload = {
      query: localStorage.getItem("search_query"),
      section: section,
    };
    dispatch(searchRequest(payload));
  };

  return (
    <Layout header={"Search"} iconUrl="/h/home">
      <Selector
        options={options}
        buttonColor="secondary.1"
        hoverColor="secondary.1"
        activeColor="secondary.1"
        onOptionSelect={handleOptionSelect}
        marginLeft="68px"
        defaultOption={
          reverse_options_map[localStorage.getItem("search_section")]
        }
      />
      <Box
        minHeight="600px"
        bgColor={"#ffff"}
        mt="20px"
        ml="10px"
        shadow="md"
        borderWidth="1px"
        borderRadius="md"
      >
        <ReactDataTable
          columns={DataTableColumns(handleViewClick)}
          data={searchSectionResponse}
          handleViewClick={handleViewClick}
          selectedPatientId={selectedPatient}
        />
        {selectedPatient && trDetails && (
          <PatientDetailsModal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              setSelectedPatient(null);
            }}
            patient={trDetails?.[0] || []}
          />
        )}
      </Box>
    </Layout>
  );
};

export default SearchResults;
