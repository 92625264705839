import React from "react";
import DataTable from "react-data-table-component";
import noDataImage from "../assets/images/TableBg.svg";

const ReactDataTable = ({
  columns,
  data,
  selectedPatientId,
  conditionalRowStyles,
  noDataComponent,
}) => {
  const customStyles = {
    rows: {
      style: {
        whiteSpace: "nowrap",
      },
    },
    cells: {
      style: {
        whiteSpace: "nowrap",
        overflow: "visible",
        textOverflow: "initial",
        paddingRight: "70px",
      },
    },
    tableWrapper: {
      style: {
        boxShadow: "var(--chakra-shadows-md)",
        // borderWidth: "1px",
        borderRadius: "var(--chakra-radii-md)",
      },
    },
    table: {
      style: {
        borderRadius: "var(--chakra-radii-md)", // Ensure the table respects the border radius
      },
    },

    headCells: {
      style: {
        backgroundColor: "#2B67AF",
        color: "white",
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    columns: {
      centerColumn: {
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  };
  const RowStyles = [
    {
      when: (row) => row.id === selectedPatientId,
      style: {
        backgroundColor: "#50C878",
      },
    },
  ];

  const NoDataComponent = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        fontSize: "20px",
      }}
    >
      <img
        src={noDataImage}
        alt="No records"
        style={{ marginBottom: "20px", maxWidth: "30%" }}
      />
      There are no records to display
    </div>
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={customStyles}
      conditionalRowStyles={
        conditionalRowStyles ? conditionalRowStyles : RowStyles
      }
      noDataComponent={noDataComponent ? noDataComponent : <NoDataComponent />}
      striped
      pagination
      responsive
      scrollable
      //   fixedHeader
      //   noHeader
      //   dense
    />
  );
};

export default ReactDataTable;
