import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  const authUser = JSON.parse(localStorage.getItem("user"));
  const isAuthenticated = authUser && authUser.role;

  return isAuthenticated ? <Navigate to="/h/home" replace /> : <Outlet />;
  // If authenticated, redirect to /h/home, otherwise render the child component (e.g., login)
};

export default PublicRoute;
