import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import PrimaryHeading from "../../../components/PrimaryHeading";
import FormInput from "../../../components/FormInput";
import {
  handleChange,
  handleMultiFileChange,
  handleMultiRemoveFile,
  handleSelectChange,
  handleViewFile,
} from "../form/utils";
import FormSelector from "../../../components/FormSelector";
import { useDispatch, useSelector } from "react-redux";
import UploadReportsSection from "../medicalOpinion/UploadReportsSection";

const FlightTicketForm = ({
  formattedLabel,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  selectedFile,
  setSelectedFile,
  // handleReportsFileChange,
  // handleViewFile,
  // removeFile,
  fileInputRef,
}) => {
  const dispatch = useDispatch();

  const {
    countryData,
    airlinesData,
    toAirportData,
    fromAirportData,
    preferredCountry,
  } = useSelector((state) => state.hcf);
  const name = "flightTickets";

  // useEffect(() => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [`${formattedLabel}FromAirport`]: null,
  //   }));
  // }, [formData[`${formattedLabel}FromCountry`]]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      [`${formattedLabel}ToAirport`]: null,
    }));
  }, [formData[`${formattedLabel}ToCountry`]]);

  return (
    <>
      {/* <PrimaryHeading mt="20px" mb="5px">
        Flight Ticket Details
      </PrimaryHeading> */}
      <Box
        display="flex"
        flexDirection="column"
        bgColor="white"
        p="0px"
        borderRadius="10px"
        // mb="20px"
      >
        <Box>
          {/* <FormInputFile
            name="flightTickets"
            label="Flight Tickets"
            formData={formData}
            setFormData={setFormData}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            isRequired={true}
            handleFileChange={handleFileChange}
            handleViewFile={handleViewFile}
            removeFile={removeFile}
          /> */}
          <UploadReportsSection
            errors={formErrors}
            selectedFile={selectedFile}
            handleMultiFileChange={(event) =>
              handleMultiFileChange(
                event,
                selectedFile,
                setSelectedFile,
                formData,
                setFormData,
                formErrors,
                setFormErrors,
                `${formattedLabel}FlightTickets`
              )
            }
            handleViewFile={handleViewFile}
            removeFile={(index) =>
              handleMultiRemoveFile(
                index,
                selectedFile,
                setSelectedFile,
                formData,
                setFormData,
                formErrors,
                setFormErrors,
                `${formattedLabel}FlightTickets`
              )
            }
            fileInputRef={fileInputRef}
            label="Upload Flight Tickets"
            id={`${formattedLabel}FlightTickets`}
          />
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          className="form-box"
        >
          <FormSelector
            id={`${formattedLabel}AirlineDetails`}
            label="Airline Details"
            name={`${formattedLabel}AirlineDetails`}
            options={airlinesData}
            placeholder="Select Airline"
            value={formData[`${formattedLabel}AirlineDetails`]}
            onChange={(selectedOption, actionMeta) =>
              handleSelectChange(
                formData,
                setFormData,
                formErrors,
                setFormErrors,
                selectedOption,
                actionMeta,
                dispatch
              )
            }
            isInvalid={!!formErrors[`${formattedLabel}AirlineDetails`]}
            errorMessage={formErrors[`${formattedLabel}AirlineDetails`]}
            isRequired={
              formattedLabel === "patient" || formattedLabel === "attendant1"
            }
            flex={{ base: "100%", md: "calc(50% - 10px)" }}
            mr={{ base: "0", md: "20px" }}
          />
          <FormInput
            id={`${formattedLabel}FlightNumber`}
            label="Flight Number"
            type="text"
            placeholder="Enter Flight Number"
            value={formData[`${formattedLabel}FlightNumber`]}
            onChange={(e) =>
              handleChange(formData, setFormData, formErrors, setFormErrors, e)
            }
            isInvalid={!!formErrors[`${formattedLabel}FlightNumber`]}
            errorMessage={formErrors[`${formattedLabel}FlightNumber`]}
            isRequired={
              formattedLabel === "patient" || formattedLabel === "attendant1"
            }
            flex={{ base: "100%", md: "calc(50% - 10px)" }}
            mr={{ base: "0", md: "0px" }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          className="form-box"
        >
          <FormInput
            id={`${formattedLabel}ArrivalDate`}
            label="Arrival Date"
            type="date"
            placeholder="Enter Arrival Date"
            value={formData[`${formattedLabel}ArrivalDate`]}
            onChange={(e) =>
              handleChange(formData, setFormData, formErrors, setFormErrors, e)
            }
            isInvalid={!!formErrors[`${formattedLabel}ArrivalDate`]}
            errorMessage={formErrors[`${formattedLabel}ArrivalDate`]}
            isRequired={
              formattedLabel === "patient" || formattedLabel === "attendant1"
            }
            flex={{ base: "100%", md: "calc(50% - 10px)" }}
            mr={{ base: "0", md: "20px" }}
            name="avoidPastDate"
          />
          <FormInput
            id={`${formattedLabel}ArrivalTime`}
            label="Arrival Time"
            type="time"
            placeholder="Enter Arrival Time"
            value={formData[`${formattedLabel}ArrivalTime`]}
            onChange={(e) =>
              handleChange(formData, setFormData, formErrors, setFormErrors, e)
            }
            isInvalid={!!formErrors[`${formattedLabel}ArrivalTime`]}
            errorMessage={formErrors[`${formattedLabel}ArrivalTime`]}
            isRequired={
              formattedLabel === "patient" || formattedLabel === "attendant1"
            }
            flex={{ base: "100%", md: "calc(50% - 10px)" }}
            mr={{ base: "0", md: "0px" }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          className="form-box"
        >
          <FormSelector
            id={`${formattedLabel}FromCountry`}
            label="From Country"
            options={countryData}
            placeholder="Enter From Country"
            value={formData[`${formattedLabel}FromCountry`]}
            onChange={(selectedOption, actionMeta) =>
              handleSelectChange(
                formData,
                setFormData,
                formErrors,
                setFormErrors,
                selectedOption,
                actionMeta,
                dispatch
              )
            }
            isInvalid={!!formErrors[`${formattedLabel}FromCountry`]}
            errorMessage={formErrors[`${formattedLabel}FromCountry`]}
            isRequired={
              formattedLabel === "patient" || formattedLabel === "attendant1"
            }
            flex={{ base: "100%", md: "calc(50% - 10px)" }}
            mr={{ base: "0", md: "20px" }}
          />
          {formattedLabel === "patient" ? (
            <FormSelector
              id={`patientToCountry`}
              label="To Country"
              options={preferredCountry}
              placeholder="Enter To Country"
              value={formData.toCountry}
              onChange={(selectedOption, actionMeta) =>
                handleSelectChange(
                  formData,
                  setFormData,
                  formErrors,
                  setFormErrors,
                  selectedOption,
                  actionMeta,
                  dispatch
                )
              }
              isInvalid={!!formErrors[`${formattedLabel}ToCountry`]}
              errorMessage={!!formErrors[`${formattedLabel}ToCountry`]}
              isRequired={formattedLabel === "patient"}
              flex={{ base: "100%", md: "calc(50% - 10px)" }}
              mr={{ base: "0", md: "0px" }}
              disabled={formattedLabel !== "patient" ? true : false}
            />
          ) : (
            <FormSelector
              label="To Country"
              options={preferredCountry}
              value={formData.patientToCountry || ""}
              flex={{ base: "100%", md: "calc(50% - 10px)" }}
              mr={{ base: "0", md: "0px" }}
              disabled={true}
            />
          )}
        </Box>

        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          className="form-box"
        >
          <FormSelector
            id={`${formattedLabel}FromAirport`}
            label="From Airport"
            // options={fromAirportData}
            options={fromAirportData[`${formattedLabel}FromAirport`] || []}
            placeholder="Select From Airport"
            value={formData[`${formattedLabel}FromAirport`]}
            onChange={(selectedOption, actionMeta) =>
              handleSelectChange(
                formData,
                setFormData,
                formErrors,
                setFormErrors,
                selectedOption,
                actionMeta
              )
            }
            isInvalid={!!formErrors[`${formattedLabel}FromAirport`]}
            errorMessage={formErrors[`${formattedLabel}FromAirport`]}
            isRequired={
              formattedLabel === "patient" || formattedLabel === "attendant1"
            }
            flex={{ base: "100%", md: "calc(50% - 10px)" }}
            mr={{ base: "0", md: "20px" }}
          />
          <FormSelector
            id={`${formattedLabel}ToAirport`}
            label="To Airport"
            options={toAirportData}
            placeholder="Select To Airport"
            value={formData[`${formattedLabel}ToAirport`]}
            onChange={(selectedOption, actionMeta) =>
              handleSelectChange(
                formData,
                setFormData,
                formErrors,
                setFormErrors,
                selectedOption,
                actionMeta
              )
            }
            isInvalid={!!formErrors[`${formattedLabel}ToAirport`]}
            errorMessage={formErrors[`${formattedLabel}ToAirport`]}
            isRequired={
              formattedLabel === "patient" || formattedLabel === "attendant1"
            }
            flex={{ base: "100%", md: "calc(50% - 10px)" }}
            mr={{ base: "0", md: "0px" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default FlightTicketForm;
