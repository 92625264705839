import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import {
  Box,
  Text,
  VStack,
  Stack,
  Card,
  CardBody,
  Box as ChakraBox,
  Flex,
  FormControl,
  Input,
  IconButton,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import noDataImage from "../../assets/images/MessageBg.svg";
import ChatBot from "./chatBot";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessagesHistoryRequest } from "./store/actions";
import PrimaryButton from "../../components/PrimaryButton";
import { FiMessageCircle } from "react-icons/fi";
import PrimaryFormLabel from "../../components/PrimaryFormLabel";
import { Select } from "chakra-react-select";
import { CiFilter } from "react-icons/ci";

const Messages = () => {
  const dispatch = useDispatch();
  const { messagesSummaryHistory } = useSelector((state) => state.messages);
  const typeData = [
    {
      value: "Medical Portal Not Working",
      label: "Medical Portal Not Working",
    },
    { value: "PickUp Details Required", label: "PickUp Details Required" },
    { value: "VIL Pending", label: "VIL Pending" },
    { value: "Medical Opinion Pending", label: "Medical Opinion Pending" },
  ];
  const statusData = [
    {
      value: "Open",
      label: "Open",
    },
    { value: "Working", label: "Working" },
    { value: "Completed", label: "Completed" },
  ];

  // const [isChatBotVisible, setIsChatBotVisible] = useState(true);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const [newChat, setNewChat] = useState(false);
  const [status, setStatus] = useState([]);
  const [type, setType] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [openMessageHistory, setOpenMessageHistory] = useState(false);

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const payload = { is_summary: true };
    dispatch(fetchMessagesHistoryRequest(payload));
  }, [dispatch]);

  useEffect(() => {
    if (showFilters) {
      setStatus([...status]);
      setType([...type]);
      setFromDate(fromDate);
      setToDate(toDate);
    }
  }, [showFilters]);

  const handleNewChatClick = () => {
    setNewChat(true);
    setOpenChat(false);
    setSelectedTaskId(null);
    setOpenMessageHistory(true);
  };

  const handleChatClick = (taskId) => {
    setSelectedTaskId(taskId);
    setOpenChat(true);
    setOpenMessageHistory(true);
    const payload = { is_summary: false, task_id: taskId };
    dispatch(fetchMessagesHistoryRequest(payload));
    // fetchHistory();
  };

  const fetchHistory = () => {
    if (newChat) {
      const payload = { is_summary: true };
      dispatch(fetchMessagesHistoryRequest(payload));
    }
  };

  const handleCloseChat = () => {
    setOpenMessageHistory(false);
    fetchHistory();
  };

  const handleFromDateChange = (e) => {
    const selectedDate = e.target.value;
    if (new Date(selectedDate) > new Date(toDate)) {
      setDateError("From date cannot be later than To date");
    } else {
      setDateError("");
    }
    setFromDate(selectedDate);
  };

  const toggleFilters = () => {
    setShowFilters((prevShowFilters) => !prevShowFilters);
  };

  const applyFilters = () => {
    if (fromDate && !toDate) {
      setDateError("Please select a 'To Date' when 'From Date' is selected");
      return;
    }
    setDateError("");
    const payload = { is_summary: true, is_filter: true };
    if (status && status.length > 0) {
      const statusList = status.map((item) => item.value);
      payload["task_status"] = statusList;
    }
    if (type && type.length > 0) {
      const typeList = type.map((item) => item.value);
      payload["category"] = typeList;
    }
    if (fromDate) payload.from_date = fromDate;
    if (toDate) payload.to_date = toDate;
    dispatch(fetchMessagesHistoryRequest(payload));
    toggleFilters();
    handleNewChatClick();
  };

  const clearFilters = () => {
    setStatus([]);
    setType([]);
    setFromDate("");
    setToDate("");
    setDateError("");
    const payload = { is_summary: true, is_filter: true };
    dispatch(fetchMessagesHistoryRequest(payload));
  };

  return (
    <Layout header={"Messages"} iconUrl="/h/home" newChat="true">
      <PrimaryButton
        rightIcon={<FiMessageCircle fontSize="20px" pb="10px" />}
        ml="10px"
        onClick={handleNewChatClick}
      >
        Start Chat
      </PrimaryButton>
      {isSmallScreen && (
        <IconButton
          color="primary.1"
          size="lg"
          aria-label="Filter"
          fontSize="30px"
          isRound={true}
          variant="solid"
          icon={<CiFilter />}
          ml="5px"
          onClick={toggleFilters}
        />
      )}
      {((isSmallScreen && showFilters) || !isSmallScreen) && (
        <Box
          id="filters"
          bg="white"
          className="collapse-section single-box"
          borderRadius={"10px"}
        >
          <Flex
            mb="10px"
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FormControl
              flex="1"
              mr={{ base: "0", md: "10px" }}
              className="form-input"
            >
              <PrimaryFormLabel>Status</PrimaryFormLabel>
              <Select
                isMulti
                placeholder="Select Status"
                closeMenuOnSelect={false}
                options={statusData}
                value={status}
                onChange={(selectedOption) => setStatus(selectedOption)}
              />
            </FormControl>
            <FormControl
              flex="1"
              mr={{ base: "0", md: "10px" }}
              className="form-input"
            >
              <PrimaryFormLabel>Type</PrimaryFormLabel>
              <Select
                isMulti
                placeholder="Select Type"
                closeMenuOnSelect={false}
                options={typeData}
                value={type}
                onChange={(selectedOption) => setType(selectedOption)}
              />
            </FormControl>
            <FormControl
              flex="1"
              mr={{ base: "0", md: "10px" }}
              className="form-input"
            >
              <PrimaryFormLabel>From Date</PrimaryFormLabel>
              <Input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={fromDate}
                onChange={handleFromDateChange}
              />
              {dateError && <Text color="red.500">{dateError}</Text>}
            </FormControl>
            <FormControl
              flex="1"
              mr={{ base: "0", md: "10px" }}
              className="form-input"
            >
              <PrimaryFormLabel>To Date</PrimaryFormLabel>
              <Input
                type="date"
                min={fromDate}
                max={new Date().toISOString().split("T")[0]}
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </FormControl>
          </Flex>
          <Flex justifyContent="flex-end">
            <Button
              onClick={clearFilters}
              mt={{ base: "10px", md: "0" }}
              mr="10px"
              colorScheme="red"
              variant="ghost"
            >
              Clear
            </Button>
            <PrimaryButton
              onClick={applyFilters}
              mt={{ base: "10px", md: "0" }}
              mr="10px"
            >
              Apply
            </PrimaryButton>
          </Flex>
        </Box>
      )}
      <Flex direction="row" height={["70vh", "70vh", "72vh"]} overflow="hidden">
        {((!openMessageHistory && isSmallScreen) || !isSmallScreen) && (
          <Box
            flex="1"
            // width="50%"
            bgColor={"#ffff"}
            mt="20px"
            ml="10px"
            shadow="md"
            borderWidth="1px"
            borderRadius="md"
            p="4"
            overflowY="auto"
          >
            {messagesSummaryHistory.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  src={noDataImage}
                  alt="No records"
                  style={{ width: "300px" }}
                />
                <Text as="b">No messages yet</Text>
              </div>
            ) : (
              <VStack spacing="4">
                {messagesSummaryHistory.map((message) => (
                  <Card
                    key={message.task_id}
                    variant="outline"
                    width="100%"
                    position="relative"
                    bg={
                      selectedTaskId === message.task_id ? "primary.1" : "white"
                    }
                    onClick={() => handleChatClick(message.task_id)}
                  >
                    <CardBody cursor={"pointer"}>
                      <Stack spacing="2">
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          color={
                            selectedTaskId === message.task_id
                              ? "white"
                              : "black"
                          }
                        >
                          {message.task_subject}
                          {message.trid ? ` - ${message.trid}` : ""}
                        </Text>

                        <Text
                          fontSize="md"
                          color={
                            selectedTaskId === message.task_id
                              ? "white"
                              : "gray.500"
                          }
                        >
                          Status: {message.task_status}
                        </Text>
                        <ChakraBox
                          position="absolute"
                          bottom="4"
                          right="4"
                          fontSize="sm"
                          color={
                            selectedTaskId === message.task_id
                              ? "white"
                              : "gray.500"
                          }
                        >
                          {message.created_at}
                        </ChakraBox>
                      </Stack>
                    </CardBody>
                  </Card>
                ))}
              </VStack>
            )}
          </Box>
        )}
        {((isSmallScreen && openMessageHistory) || !isSmallScreen) && (
          <Box
            flex="1"
            mt="20px"
            ml="10px"
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            // minHeight="600px"
            background={"white"}
            display="flex"
            flexDirection="column"
            overflowY="auto"
          >
            <ChatBot
              // isChatBotVisible={isChatBotVisible}
              // setIsChatBotVisible={setIsChatBotVisible}
              openChat={openChat}
              selectedTaskId={selectedTaskId}
              newChat={newChat}
              setNewChat={setNewChat}
              setSelectedTaskId={setSelectedTaskId}
              isSmallScreen={isSmallScreen}
              openMessageHistory={openMessageHistory}
              handleCloseChat={handleCloseChat}
            />
          </Box>
        )}
      </Flex>
    </Layout>
  );
};

export default Messages;
