export const baseFormData = {
  healthConcern: "",
  preferredCountry: "",
  hospital: "",
  name: "",
  passportNumber: "",
  dob: "",
  gender: "",
  nationality: "",
  countryOfResidence: "",
  countryCode: "",
  mobileNumber: "",
  passport: "",
  age: "",
};

export const medicalOpinionFormData = {
  ...baseFormData,
  reports: [],
  remarks: "",
};

export const preIntimationFormData = {
  ...baseFormData,
  patientMovingFrom: "",
  arrivalWithin: "",
};
