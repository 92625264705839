import { SidebarContent, MobileNav } from "./SidebarWithHeader";
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  IconButton,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryHeading from "../components/PrimaryHeading";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import "./Layout.css";
import Alert from "../components/Alert";
import LoadingSpinner from "../components/LoadingSpinner";
import { CiFilter } from "react-icons/ci";
import { useDispatch } from "react-redux";
import {
  logoutRequest,
  resetErrors,
} from "../features/authentication/authStore/actions";
import "./base.css";
import FilterDrawer from "../components/FilterDrawer";

const Layout = ({
  children,
  header,
  iconUrl,
  showFilters = false,
  onOpenDrawer,
  handleFilter,
  setFilters,
  filters,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { onClose, onOpen, isOpen } = useDisclosure();
  const [alertTitle, setAlertTitle] = useState("Warning!");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const {
    apiErrorRes,
    loading,
    isSessionExpiry,
    isSessionAPIError,
    isInternalServerError,
    apiError,
  } = useSelector((state) => state.auth);

  const handleIconClick = () => {
    if (iconUrl) {
      navigate(iconUrl);
    }
  };

  useEffect(() => {
    setIsAlertOpen(false);
    setIsAlertOpen(false);
    if (isSessionExpiry) {
      // localStorage.clear();
      setIsAlertOpen(true);
      setAlertTitle("Session has expired");
      setErrorMessage("Your login has changed/expired... Please login again");
    } else if (isSessionAPIError) {
      setIsAlertOpen(true);
      setErrorMessage(apiError);
    } else if (isInternalServerError) {
      setIsAlertOpen(true);
      setErrorMessage(
        "Try refreshing your browser. If the issue persists, please contact MediJourn support"
      );
    }
    if (apiErrorRes) {
      setIsAlertOpen(true);
      setErrorMessage(apiErrorRes.message);
    }
  }, [apiErrorRes, isSessionAPIError, isSessionExpiry, isInternalServerError]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleAlertOkAction = () => {
    if (isSessionExpiry) {
      const payload = { navigate: navigate };
      dispatch(logoutRequest(payload));
      // navigate("/");
    } else {
      dispatch(resetErrors());
    }
  };

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      {/* <Box minH="100vh" bg="white"> */}
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Flex justifyContent="space-between" alignItems="center" mb="4">
          {header && (
            <PrimaryHeading pl="10px" size="lg">
              {header}
            </PrimaryHeading>
          )}
          <Spacer />
          {showFilters && (
            <>
              <IconButton
                color="primary.1"
                size="lg"
                aria-label="Filter"
                fontSize="30px"
                isRound={true}
                variant="solid"
                icon={<CiFilter />}
                onClick={() => setIsDrawerOpen(true)}
              />
              <FilterDrawer
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                handleFilter={handleFilter}
                setFilters={setFilters}
                filters={filters}
              />
            </>
          )}
          {iconUrl && (
            <IconButton
              color="primary.1"
              size="lg"
              aria-label="Go Back"
              fontSize="30px"
              isRound={true}
              variant="solid"
              icon={<IoArrowBackCircleOutline />}
              onClick={handleIconClick}
            />
          )}
        </Flex>
        <main className="main-container">
          {isLoading && <LoadingSpinner isLoading={isLoading} />}
          <div className={`content ${isLoading ? "blurred" : ""}`}>
            {children}
          </div>
          <Alert
            isOpen={isAlertOpen}
            title={alertTitle}
            onClose={() => {
              dispatch(resetErrors());
              setIsAlertOpen(false);
              setErrorMessage(false);
            }}
            body={errorMessage}
            callback={handleAlertOkAction}
          />
        </main>

        {/* <ErrorPopup
          isOpen={isAlertOpen}
          onClose={handleClose}
          body={errorMessage}
        /> */}
      </Box>
    </Box>
  );
};

export default Layout;
